import { roll } from '../grade2/utils';

const isPrime = (num) => {
  if (num < 2) return false;
  for (let i = 2; i <= Math.sqrt(num); i++) {
    if (num % i === 0) return false;
  }
  return true;
};

export const multiplesFactors = {
  "Multiples and Factors": {
    levels: [
      { id: "xj309m4n", description: "Count Up by 3 from 30",  exerciseType: "CountingPractice", timeLimit: 90, inputs: { start: 33, increment: 3 } },
      { id: "xd609m4n", description: "Count Down by 3 from 60",  exerciseType: "CountingPractice", timeLimit: 90, inputs: { start: 60, increment: -3 } },
      { id: "xj609m4n", description: "Count Up by 3 from 60",  exerciseType: "CountingPractice", timeLimit: 90, inputs: { start: 63, increment: 3 } },
      { id: "xd909m4n", description: "Count Down by 3 from 90",  exerciseType: "CountingPractice", timeLimit: 90, inputs: { start: 90, increment: -3 } },
      { id: "xj119m4n", description: "Count Up by 11 from 11",  exerciseType: "CountingPractice", timeLimit: 90, inputs: { start: 11, increment: 11 } },
      { id: "xj129m4n", description: "Count Up by 12 from 12",  exerciseType: "CountingPractice", timeLimit: 90, inputs: { start: 12, increment: 12 } },
      { id: "xj159m4n", description: "Count Up by 15 from 15",  exerciseType: "CountingPractice", timeLimit: 90, inputs: { start: 15, increment: 15 } },
      { id: "xj139m4n", description: "Count Up by 13 from 13",  exerciseType: "CountingPractice", timeLimit: 90, inputs: { start: 13, increment: 13 } },
      { id: "xj149m4n", description: "Count Up by 14 from 14",  exerciseType: "CountingPractice", timeLimit: 90, inputs: { start: 14, increment: 14 } },
      { id: "xj169m4n", description: "Count Up by 16 from 16",  exerciseType: "CountingPractice", timeLimit: 90, inputs: { start: 16, increment: 16 } },
      { id: "xj199m4n", description: "Count Up by 19 from 19",  exerciseType: "CountingPractice", timeLimit: 90, inputs: { start: 19, increment: 19 } },
      { id: "xj189m4n", description: "Count Up by 18 from 18",  exerciseType: "CountingPractice", timeLimit: 90, inputs: { start: 18, increment: 18 } },
      { id: "xj179m4n", description: "Count Up by 17 from 17",  exerciseType: "CountingPractice", timeLimit: 90, inputs: { start: 17, increment: 17 } },
      { id: "div2", description: "Divisible by 2", exerciseType: "MultipleChoicePractice", timeLimit: 30, inputs: () => {
        const number = roll(10, 99);
        return {
          question: `Is ${number} divisible by 2?`,
          choices: ["Yes", "No"],
          correctAnswer: number % 2 === 0 ? "Yes" : "No"
        };
      }},
      { id: "div5", description: "Divisible by 5", exerciseType: "MultipleChoicePractice", timeLimit: 30, inputs: () => {
        const number = roll(1,9)*10 + [0,1,2,3,4,5,6,7,8,9,0,0,5,5][roll(0,13)];
        return {
          question: `Is ${number} divisible by 5?`,
          choices: ["Yes", "No"],
          correctAnswer: number % 5 === 0 ? "Yes" : "No"
        };
      }},
      { id: "div3", description: "Divisible by 3", exerciseType: "MultipleChoicePractice", timeLimit: 30, inputs: () => {
        const number = roll(10, 99);
        const divisor = 3
        return {
          question: `Is ${number} divisible by ${divisor}?`,
          choices: ["Yes", "No"],
          correctAnswer: number % divisor === 0 ? "Yes" : "No"
        };
      }},
      { id: "div235", description: "Divisible by 2, 3, or 5", exerciseType: "MultipleChoicePractice", timeLimit: 30, inputs: () => {
        const number = roll(10, 99);
        const divisor = [2,3,5][roll(0,2)]
        return {
          question: `Is ${number} divisible by ${divisor}?`,
          choices: ["Yes", "No"],
          correctAnswer: number % divisor === 0 ? "Yes" : "No"
        };
      }},
      { id: "div4", description: "Divisible by 4", exerciseType: "MultipleChoicePractice", timeLimit: 45, inputs: () => {
        const number = roll(10, 49)*2;
        return {
          question: `Is ${number} divisible by 4?`,
          choices: ["Yes", "No"],
          correctAnswer: number % 4 === 0 ? "Yes" : "No"
        };
      }},
      { id: "div6", description: "Divisible by 6", exerciseType: "MultipleChoicePractice", timeLimit: 45, inputs: () => {
        const number = roll(10, 49)*2;
        return {
          question: `Is ${number} divisible by 6?`,
          choices: ["Yes", "No"],
          correctAnswer: number % 6 === 0 ? "Yes" : "No"
        };
      }},
      { id: "factors20", description: "Factors up to 20", exerciseType: "FactorPractice", timeLimit: 240, inputs: () => ({ number: roll(2,20) }) },
      { id: "factors40", description: "Factors up to 40", exerciseType: "FactorPractice", timeLimit: 240, inputs: () => ({ number: roll(21,40) }) },
      { id: "factors60", description: "Factors up to 60", exerciseType: "FactorPractice", timeLimit: 240, inputs: () => ({ number: roll(41,60) }) },
      { id: "factors80", description: "Factors up to 80", exerciseType: "FactorPractice", timeLimit: 240, inputs: () => ({ number: roll(61,80) }) },
      { id: "factors100", description: "Factors up to 100", exerciseType: "FactorPractice", timeLimit: 240, inputs: () => ({ number: roll(81,100) }) },
      { id: "prime", description: "Prime or Composite", exerciseType: "MultipleChoicePractice", timeLimit: 45, inputs: () => {
        const number = roll(2, 49)*2+12;
        return {
          question: `Is ${number} prime or composite?`,
          choices: ["Prime", "Composite"],
          correctAnswer: isPrime(number) ? "Prime" : "Composite"
        };
      }},
    ]
  }
};

import React, { useState, useEffect, useMemo } from 'react';
import { RadioGroup, FormControlLabel, Radio } from '@mui/material';

const ComparingFractionsPractice = React.memo(({ inputs: inputsFn, onAnswer }) => {
    const [inputValue, setInputValue] = useState('');
    const [problem, setProblem] = useState(() => {
        const { fraction1, fraction2 } = typeof inputsFn === 'function' ? inputsFn() : inputsFn;
        const val1 = fraction1.numerator / fraction1.denominator;
        const val2 = fraction2.numerator / fraction2.denominator;
        
        let correctAnswer;
        if (val1 < val2) correctAnswer = '<';
        else if (val1 > val2) correctAnswer = '>';
        else correctAnswer = '=';

        return {
            fraction1,
            fraction2,
            correctAnswer
        };
    });
    
    const choices = useMemo(() => ['<', '>', '='], []);

    // Handle number keys (1-3)
    useEffect(() => {
        const handleKeyPress = (e) => {
            const num = parseInt(e.key);
            if (num >= 1 && num <= choices.length) {
                setInputValue(choices[num - 1]);
            }
        };

        document.addEventListener('keydown', handleKeyPress);
        return () => document.removeEventListener('keydown', handleKeyPress);
    }, [choices]);

    // Handle Enter key for submission
    useEffect(() => {
        const handleEnterKey = (e) => {
            if (e.key === 'Enter' && inputValue) {
                e.preventDefault();
                handleSubmit(e);
            }
        };

        document.addEventListener('keydown', handleEnterKey);
        return () => document.removeEventListener('keydown', handleEnterKey);
        //eslint-disable-next-line
    }, [inputValue]);

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!inputValue) return;
        
        onAnswer(
            inputValue === problem.correctAnswer ? 'correct' : inputValue,
            problem.correctAnswer
        );
        setInputValue('');

        // Generate new problem after submission
        const { fraction1, fraction2 } = typeof inputsFn === 'function' ? inputsFn() : inputsFn;
        const val1 = fraction1.numerator / fraction1.denominator;
        const val2 = fraction2.numerator / fraction2.denominator;
        
        let correctAnswer;
        if (val1 < val2) correctAnswer = '<';
        else if (val1 > val2) correctAnswer = '>';
        else correctAnswer = '=';

        setProblem({
            fraction1,
            fraction2,
            correctAnswer
        });
    };

    const fractionStyle = {
        fontSize: '2rem',
        fontWeight: 'bold',
        textAlign: 'center',
        width: '60px'
    };

    const operatorStyle = {
        fontSize: '2rem',
        width: '60px',
        height: '60px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: 'white'
    };

    const hrStyle = {
        border: '2px solid white',
        width: '100%',
        margin: '8px 0'
    };

    return (
        <div className="content-box">
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '2rem' }}>
                <div style={fractionStyle}>
                    <div>{problem.fraction1.numerator}</div>
                    <hr style={hrStyle}/>
                    <div>{problem.fraction1.denominator}</div>
                </div>
                
                <div style={operatorStyle}>
                    {inputValue || ' '}
                </div>

                <div style={fractionStyle}>
                    <div>{problem.fraction2.numerator}</div>
                    <hr style={hrStyle}/>
                    <div>{problem.fraction2.denominator}</div>
                </div>
            </div>

            <form onSubmit={handleSubmit} style={{ marginTop: '2rem' }}>
                <RadioGroup
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                    className="radio-group"
                    style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', gap: '2rem' }}
                >
                    {choices.map((choice, index) => (
                        <FormControlLabel
                            key={index}
                            value={choice}
                            control={<Radio />}
                            label={`${index + 1}. ${choice}`}
                            className="radio-option"
                        />
                    ))}
                </RadioGroup>
            </form>
        </div>
    );
});

export default ComparingFractionsPractice;

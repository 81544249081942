import { roll } from './utils';

export const fractions = {
    "Fractions" : {
      levels : [
        { id: "f9n0u1t2", description: "Name Unit Fractions", exerciseType: "NamingFractionsPractice", timeLimit: 90, inputs: () => {
          const denominator = roll(2, 6);
          return {
            numerator: 1,
            denominator,
            shape: ['circle', 'rectangle'][roll(0,1)]
          };
        }},
        { id: "f9nll1t2", description: "Name Unit Fractions on Number Line", exerciseType: "NamingFractionsPractice", timeLimit: 90, inputs: () => {
          const denominator = roll(2, 6);
          return {
            numerator: 1,
            denominator,
            shape: 'numberline'
          };
        }},
        { id: "f9c0m1t1", description: "Compare Unit Fractions", exerciseType: "ComparingFractionsPractice", timeLimit: 90, inputs: () => {
          const den1 = roll(2, 10);
          let den2;
          do {
            den2 = roll(2, 10);
          } while (den2 === den1);

          const num1 = 1;
          const num2 = 1;
          
          return {
            fraction1: { numerator: num1, denominator: den1 },
            fraction2: { numerator: num2, denominator: den2 }
          };
        }},
        { id: "f9n0u1t3", description: "Name Proper Fractions", exerciseType: "NamingFractionsPractice", timeLimit: 90, inputs: () => {
          const denominator = roll(2, 10);
          return {
            numerator: roll(1, denominator - 1),
            denominator,
            shape: ['circle', 'rectangle'][roll(0,1)]
          };
        }},
        { id: "f9nllu1t3", description: "Name Proper Fractions on Number Line", exerciseType: "NamingFractionsPractice", timeLimit: 90, inputs: () => {
          const denominator = roll(2, 10);
          return {
            numerator: roll(1, denominator - 1),
            denominator,
            shape:  'numberline'
          };
        }},
        { id: "f9c0m1t2", description: "Compare Fractions with Same Numerator", exerciseType: "ComparingFractionsPractice", timeLimit: 90, inputs: () => {
          const den1 = roll(3, 10);
          let den2;
          do {
            den2 = roll(3, 10);
          } while (den2 === den1);
          
          let num1;
          if (den1 > den2) {
            num1 = roll(2, Math.floor(den1 / den2));
          } else {
            num1 = roll(2, den1 - 1);
          }
          
          return {
            fraction1: { numerator: num1, denominator: den1 },
            fraction2: { numerator: num1, denominator: den2 }
          };
        }},
        { id: "f9c0m1t3", description: "Compare Fractions with Same Denominator", exerciseType: "ComparingFractionsPractice", timeLimit: 90, inputs: () => {
          const denominator = roll(3, 10);
          
          const num1 = roll(1, denominator - 1);
          let num2;
          do {
            num2 = roll(1, denominator - 1);
          } while (num2 === num1);
          
          return {
            fraction1: { numerator: num1, denominator },
            fraction2: { numerator: num2, denominator }
          };
        }},
        { id: "f9c0zzt1", description: "Compare Proper Fractions", exerciseType: "ComparingFractionsPractice", timeLimit: 90, inputs: () => {
          const den1 = roll(2, 6);
          let den2;
          do {
            den2 = roll(2, 6);
          } while (den2 === den1);

          const num1 = roll(1, den1 - 1);
          const num2 = roll(1, den2 - 1);
          
          return {
            fraction1: { numerator: num1, denominator: den1 },
            fraction2: { numerator: num2, denominator: den2 }
          };
        }},
        { id: "f9eqv1t1", description: "Find Equivalent Fractions (Unit Fractions to Missing Numerator)", exerciseType: "EquivalentFractionsPractice", timeLimit: 90, inputs: () => {
          const den1 = roll(2, 5);
          const num1 = 1
          
          const factor = roll(2, 5);
          const num2 = num1 * factor;
          const den2 = den1 * factor;
          
          const blank = 'numerator'
          
          const given = blank === 'numerator' ? den2 : num2;
          
          return {
            num1,
            den1,
            given,
            blank
          };
        }},
        { id: "f9eqv1dd", description: "Find Equivalent Fractions (Unit Fractions to Missing Denominator)", exerciseType: "EquivalentFractionsPractice", timeLimit: 90, inputs: () => {
          const den1 = roll(2, 5);
          const num1 = 1
          
          const factor = roll(2, 5);
          const num2 = num1 * factor;
          const den2 = den1 * factor;
          
          const blank = 'denominator'
          
          const given = blank === 'numerator' ? den2 : num2;
          
          return {
            num1,
            den1,
            given,
            blank
          };
        }},
        { id: "f9eqv1ah", description: "Find Equivalent Fractions", exerciseType: "EquivalentFractionsPractice", timeLimit: 90, inputs: () => {
          const den1 = roll(3, 10);
          const num1 = roll(2, den1 -1)
          
          const factor = roll(2, 5);
          const num2 = num1 * factor;
          const den2 = den1 * factor;
          
          const blank = ['numerator','denominator'][roll(0,1)]
          
          const given = blank === 'numerator' ? den2 : num2;
          
          return {
            num1,
            den1,
            given,
            blank
          };
        }}
      ]
    }
};

import { decimals } from './grade5/decimals';
import { multiplication } from './grade5/multiplication';
import { exponents } from './grade5/exponents';

const grade5Content = {
  ...exponents,
  ...decimals,
  ...multiplication
};

export default grade5Content;

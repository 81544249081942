import { roll } from './utils';

export const doublesHalves = {
    "Doubles and Halves" : {
        levels: [
              { id: "s2b6c8n1", description: "Half of Odd Numbers to 10", exerciseType: "HalvesPractice", timeLimit: 60, inputs: () => ({
                number: roll(1, 5) * 2 - 1
              })},
              { id: "k5w9r2t6", description: "Half of All Numbers to 10", exerciseType: "HalvesPractice", timeLimit: 60, inputs: () => ({
                number: roll(1, 10)
              })},
              { id: "m7x1v4q9", description: "Half of Odd Numbers to 20", exerciseType: "HalvesPractice", timeLimit: 45, inputs: () => ({
                number: roll(6, 10) * 2 - 1
              })},
              { id: "h3y7f5l8", description: "Half of All Numbers to 20", exerciseType: "HalvesPractice", timeLimit: 45, inputs: () => ({
                number: roll(11, 20)
              })},
              { id: "h3y4f50z", description: "Half of Two Digit Numbers with Even Tens", exerciseType: "HalvesPractice", timeLimit: 45, inputs: () => ({
                number: roll(1,4) * 20 + roll(1,9)
              })},
              { id: "r9y4f2l6", description: "Doubles of Two Digit Numbers with 5 in Ones Place", exerciseType: "OperationsPractice", timeLimit: 45, inputs: () => {
                  const num = roll(1,4)*10 + 5;
                  return { num1: num, num2: num, operation: '+' };
                }},
              { id: "hry7f50z", description: "Half of Odd Tens", exerciseType: "HalvesPractice", timeLimit: 75, inputs: () => ({
                number: roll(2,5) * 20 - 10
              })},
              { id: "h3y7fzgz", description: "Half of Two Digit Even Numbers with Odd Tens", exerciseType: "HalvesPractice", timeLimit: 75, inputs: () => ({
                number: roll(2,5) * 20 - 10 + roll(1,4)*2
              })},
              { id: "zay7f50z", description: "Half of Two Digit Numbers", exerciseType: "HalvesPractice", timeLimit: 75, inputs: () => ({
                number: roll(1,9) * 10 + roll(1,9)
              })}
        ]
    }
};

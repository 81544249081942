import { useState, useEffect } from 'react';
import { Typography, TextField, Grid } from '@mui/material';
import '../../../styles/practice.css';

export default function CountingPractice({ level, onAnswer, currentProgress, inputs }) {
  const [answers, setAnswers] = useState(Array(9).fill(''));
  const [validAnswers, setValidAnswers] = useState(Array(9).fill('')); // Store validated answers separately
  
  const startNumber = inputs.start;
  const countBy = inputs.increment;
  
  const isCountingBackwards = countBy < 0;

  // Convert mixed number or fraction to decimal for comparison only
  const parseToDecimal = (value) => {
    // Try as regular decimal first
    if (!isNaN(value)) {
      return Number(value);
    }

    // Handle mixed numbers (e.g. "1 1/2")
    if (value.includes(' ')) {
      const [whole, fraction] = value.split(' ');
      if (!fraction.includes('/')) return null;
      
      const wholeNum = Number(whole);
      if (isNaN(wholeNum)) return null;
      
      const [numerator, denominator] = fraction.split('/').map(Number);
      if (!denominator || isNaN(numerator) || isNaN(denominator)) return null;
      
      return wholeNum + (numerator / denominator);
    }

    // Handle simple fractions (e.g. "1/2")
    if (value.includes('/')) {
      const [numerator, denominator] = value.split('/').map(Number);
      if (!denominator || isNaN(numerator) || isNaN(denominator)) return null;
      return numerator / denominator;
    }

    return null;
  };

  // Validate numeric input including decimals, fractions, and mixed numbers
  const isValidInput = (value) => {
    if (value === '' || value === '-') return true;
    
    // Check decimal format
    if (/^-?\d*\.?\d*$/.test(value)) return true;
    
    // Check fraction format
    if (/^-?\d*\/?\d*$/.test(value)) return true;
    
    // Check mixed number format (e.g. "1 1/2")
    if (/^-?\d+\s+\d*\/?\d*$/.test(value)) return true;
    
    return false;
  };

  // Reset answers when currentProgress changes
  useEffect(() => {
    if (currentProgress === 1) {
      setAnswers(Array(9).fill(''));
      setValidAnswers(Array(9).fill(''));
      const firstInput = document.querySelector('input[name="answer-0"]');
      if (firstInput) {
        firstInput.focus();
      }
    }
  }, [currentProgress]);

  useEffect(() => {
    setAnswers(Array(9).fill(''));
    setValidAnswers(Array(9).fill(''));
    const firstInput = document.querySelector('input[name="answer-0"]');
    if (firstInput) {
      firstInput.focus();
    }
  }, [level]);

  const validateSingleAnswer = (index, value) => {
    if (value === '') return false;
    const expectedNumber = startNumber + (countBy * (index + 1));
    const parsedValue = parseToDecimal(value);
    if (parsedValue === null) return false;
    
    // Use small epsilon for floating point comparison
    return Math.abs(parsedValue - expectedNumber) < 0.0001;
  };

  const isBoxEnabled = (index) => {
    if (index === 0 && currentProgress === 1) return true;
    if (index < currentProgress - 1) return false;
    if (index > currentProgress - 1) return false;
    
    const prevAnswer = answers[index - 1];
    if (prevAnswer === '') return false;
    
    return validateSingleAnswer(index - 1, prevAnswer);
  };

  const validateAndProgress = (index) => {
    const currentAnswer = answers[index];
    if (currentAnswer === '') return;
    if (index < currentProgress - 1) return;

    const isCorrect = validateSingleAnswer(index, currentAnswer);

    if (isCorrect) {
      // Store the valid answer in its original format
      const newValidAnswers = [...validAnswers];
      newValidAnswers[index] = currentAnswer;
      setValidAnswers(newValidAnswers);
      
      onAnswer('correct', 'correct');
      
      if (index < answers.length - 1) {
        const nextInput = document.querySelector(`input[name="answer-${index + 1}"]`);
        if (nextInput) {
          nextInput.focus();
        }
      }
    } else {
      onAnswer('incorrect', 'correct');
      setAnswers(prev => {
        const newAnswers = [...prev];
        newAnswers[index] = '';
        return newAnswers;
      });
    }
  };

  const handleInputChange = (index, value) => {
    if (!isValidInput(value)) return;
    if (index < currentProgress - 1) return;

    const newAnswers = [...answers];
    newAnswers[index] = value;
    setAnswers(newAnswers);
  };

  const handleKeyDown = (e, index) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      validateAndProgress(index);
    }
  };

  useEffect(() => {
    if (currentProgress > 1) {
      // Fill in previous answers using stored valid answers
      const newAnswers = [...answers];
      for (let i = 0; i < currentProgress - 1; i++) {
        newAnswers[i] = validAnswers[i] || '';
      }
      setAnswers(newAnswers);

      const nextInput = document.querySelector(`input[name="answer-${currentProgress - 1}"]`);
      if (nextInput) {
        nextInput.focus();
      }
    }
    //eslint-disable-next-line
  }, [currentProgress, validAnswers]);

  const inputProps = {
    style: {
      color: 'white',
      WebkitTextFillColor: 'white',
      opacity: 1
    }
  };

  return (
    <div className="content-box">
      <Typography className="practice-title">
        {isCountingBackwards 
          ? `Count backwards by ${Math.abs(countBy)} starting at ${startNumber}`
          : `Count by ${countBy} starting at ${startNumber}`}
      </Typography>
      <br/>

      <Grid container className="grid-container">
        <Grid item>
          <TextField
            disabled
            value={startNumber}
            variant="outlined"
            size="small"
            className="number-input"
            autoComplete="off"
            inputProps={inputProps}
          />
        </Grid>
        {answers.slice(0, 4).map((answer, index) => (
          <Grid item key={index}>
            <TextField
              name={`answer-${index}`}
              value={answer}
              onChange={(e) => handleInputChange(index, e.target.value)}
              onKeyDown={(e) => handleKeyDown(e, index)}
              disabled={!isBoxEnabled(index)}
              variant="outlined"
              size="small"
              className="number-input"
              autoComplete="off"
              inputProps={inputProps}
            />
          </Grid>
        ))}
      </Grid>

      <Grid container className="grid-container">
        {answers.slice(4, 9).map((answer, index) => (
          <Grid item key={index + 4}>
            <TextField
              name={`answer-${index + 4}`}
              value={answer}
              onChange={(e) => handleInputChange(index + 4, e.target.value)}
              onKeyDown={(e) => handleKeyDown(e, index + 4)}
              disabled={!isBoxEnabled(index + 4)}
              variant="outlined"
              size="small"
              className="number-input"
              autoComplete="off"
              inputProps={inputProps}
            />
          </Grid>
        ))}
      </Grid>
    </div>
  );
}

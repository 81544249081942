import { useState, useEffect } from 'react';
import { Typography, Box, LinearProgress } from '@mui/material';
import BackButton from '../../global/BackButton';

export default function PracticeHeader({ 
  grade, 
  topic, 
  level, 
  progress, 
  ANSWERS_TO_LEVEL_UP,
  levelComplete,
  bestTime,
  startTime,
  timeLeft,
  timeLimit
}) {
  const [currentTime, setCurrentTime] = useState(0);

  useEffect(() => {
    if (levelComplete) return;

    const interval = setInterval(() => {
      if (startTime) {
        const elapsed = Math.floor((Date.now() - startTime) / 1000);
        setCurrentTime(elapsed);
      }
    }, 100);

    return () => clearInterval(interval);
  }, [startTime, levelComplete]);

  const formatTime = (seconds) => {
    if (seconds === null || seconds === undefined) return '--';
    return `${seconds}s`;
  };

  return (
    <>
      <div className="practice-header">
        <BackButton 
          to={`/grade/${grade}`} 
          tooltip={`Back to Grade ${grade}`}
        />
        <Typography variant="h4" className="practice-title">
          Grade {grade} - {topic.charAt(0).toUpperCase() + topic.slice(1).replace('-', ' ')} - Level {level}
        </Typography>
      </div>

      <Box className="progress-section">
        <Box className="progress-header">
          <Typography>
            Level: {level} - Progress: {progress.completed}/{ANSWERS_TO_LEVEL_UP}
          </Typography>
          <Box className="time-display">
            <Typography className="current-time">
              Time: {formatTime(currentTime)} / {formatTime(timeLimit)}
            </Typography>
            {bestTime !== null && (
              <Typography className="best-time">
                Best: {formatTime(bestTime)}
              </Typography>
            )}
          </Box>
        </Box>
        <LinearProgress 
          variant="determinate" 
          value={(progress.completed / ANSWERS_TO_LEVEL_UP) * 100} 
          className="progress-bar"
        />
      </Box>
    </>
  );
}

const MultipleChoicePractice = ({ inputs }) => {
  const { question, choices, correctAnswer } = inputs;

  return {
    problem: {
      question,
      answer: correctAnswer,
      choices
    },
    validateAnswer: (input) => choices.includes(input)
  };
};

export default MultipleChoicePractice;

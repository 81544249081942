import { roll } from './utils';

export const placeValue = {
  "Place Value (Hundreds Tens Ones)": {
    levels: [
      { id: "f4y8l2s5", description: "Count Up by 10 from 10", exerciseType: "CountingPractice", timeLimit: 60, inputs: { start: 10, increment: 10 } },
      { id: "b7c1n6m3", description: "Count Down by 10 from 100", exerciseType: "CountingPractice", timeLimit: 60, inputs: { start: 100, increment: -10 } },
      { id: "f4y8l2as", description: "Count Up by 10", exerciseType: "CountingPractice", timeLimit: 60, inputs: { start: roll(2,9), increment: 10 } },
      { id: "b7c1n6gz", description: "Count Down by 10", exerciseType: "CountingPractice", timeLimit: 60, inputs: { start: roll(91,98), increment: -10 } },
      { id: "d8l2s7b4", description: "Count Up by 100 from 100", exerciseType: "CountingPractice", timeLimit: 45, inputs: { start: 100, increment: 100 } },
      { id: "d8l2s7p9", description: "Count Down by 100 from 1000", exerciseType: "CountingPractice", timeLimit: 45, inputs: { start: 1000, increment: -100 } },
      { id: "k9m4n7p2", description: "Count Up by 100", exerciseType: "CountingPractice", timeLimit: 45, inputs: { start: roll(11, 99), increment: 100}},
      { id: "k9m4n7r5", description: "Count Down by 100", exerciseType: "CountingPractice", timeLimit: 45, inputs: { start: roll(911, 999), increment: -100}},
      { 
        id: "q9x4v8m2", 
        description: "Place Value of Three Digit Numbers", 
        exerciseType: "PlaceValuePractice", 
        timeLimit: 45, 
        inputs: () => {
          return {
            number: roll(100, 999),
            places: [10**roll(0,2)]
          }
        }
      },
      { id: "r4tzy9u2", description: "Convert Between Ones and Tens", exerciseType: "ConversionPractice", timeLimit: 45, inputs: () => {
        const units = ['one', 'ten'];
        const fromIndex = roll(0, 1);
        let toIndex;
        do {
          toIndex = roll(0, 1);
        } while (toIndex === fromIndex);
        
        const fromUnit = units[fromIndex];
        const toUnit = units[toIndex];
        
        let value;
        if (fromUnit === 'one') {
          value = roll(1, 9) * (toUnit === 'hundred' ? 100 : 10);
        } else if (fromUnit === 'ten') {
          value = roll(1, 9) * (toUnit === 'hundred' ? 10 : 1);
        } else {
          value = roll(1, 9);
        }
        
        return {
          values: { [fromUnit]: value },
          fromUnit,
          toUnit,
          type: 'place_value'
        };
      }},
      { id: "r4t7y9u2", description: "Convert Between Ones, Tens, and Hundreds", exerciseType: "ConversionPractice", timeLimit: 45, inputs: () => {
        const units = ['one', 'ten', 'hundred'];
        const fromIndex = roll(0, 2);
        let toIndex;
        do {
          toIndex = roll(0, 2);
        } while (toIndex === fromIndex);
        
        const fromUnit = units[fromIndex];
        const toUnit = units[toIndex];
        
        let value;
        if (fromUnit === 'one') {
          value = roll(1, 9) * (toUnit === 'hundred' ? 100 : 10);
        } else if (fromUnit === 'ten') {
          value = roll(1, 9) * (toUnit === 'hundred' ? 10 : 1);
        } else {
          value = roll(1, 9);
        }
        
        return {
          values: { [fromUnit]: value },
          fromUnit,
          toUnit,
          type: 'place_value'
        };
      }}
    ]
  }
};

import { useState, useEffect } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { useAuth } from '../contexts/AuthContext';
import { db } from '../firebase';
import gradeContent from '../components/grades/gradeContent';

const useGradeProgress = (grade) => {
  const [levelBestTimes, setLevelBestTimes] = useState({});
  const [loading, setLoading] = useState(true);
  const [completionPercentage, setCompletionPercentage] = useState(0);
  const { currentUser } = useAuth();

  useEffect(() => {
    const loadProgress = async () => {
      if (!currentUser) {
        setLoading(false);
        return;
      }

      try {
        const userRef = doc(db, 'users', currentUser.uid);
        const userDoc = await getDoc(userRef);
        
        if (userDoc.exists()) {
          const userData = userDoc.data();
          // Get the map of level best times
          const times = { ...userData.levelBestTimes } || {};
          
          // Handle legacy data - convert completedLevels to times
          if (userData.completedLevels) {
            userData.completedLevels.forEach(levelId => {
              if (!times[levelId]) {
                times[levelId] = 0; // Use 0 as placeholder time for legacy data
              }
            });
          }
          
          setLevelBestTimes(times);

          // Calculate completion percentage
          if (grade && gradeContent[grade]) {
            let totalLevels = 0;
            let completedCount = 0;

            // Count total levels and check completed ones
            Object.values(gradeContent[grade]).forEach((topicData) => {
              topicData.levels.forEach(level => {
                totalLevels++;
                if (times[level.id] !== undefined) {
                  completedCount++;
                }
              });
            });

            const percentage = totalLevels > 0 ? Math.round((completedCount / totalLevels) * 100) : 0;
            setCompletionPercentage(percentage);
          }
        } else {
          setLevelBestTimes({});
          setCompletionPercentage(0);
        }
      } catch (error) {
        console.error('Error loading grade progress:', error);
        setLevelBestTimes({});
        setCompletionPercentage(0);
      }
      
      setLoading(false);
    };

    loadProgress();
  }, [currentUser, grade]);

  const getLevelBestTime = (topicName, levelIndex) => {
    // Get the level ID from grade content
    const levelData = gradeContent[grade]?.[topicName]?.levels[levelIndex - 1];
    if (!levelData) return null;
    
    // Return the best time, or null if no completion
    return levelBestTimes[levelData.id] !== undefined ? levelBestTimes[levelData.id] : null;
  };

  const isLevelCompleted = (topicName, levelIndex) => {
    const bestTime = getLevelBestTime(topicName, levelIndex);
    return bestTime !== null;
  };

  return {
    isLevelCompleted,
    getLevelBestTime,
    loading,
    completionPercentage
  };
};

export default useGradeProgress;

import { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Alert } from '@mui/material';
import usePracticeProgress from '../../hooks/usePracticeProgress';
import { exerciseComponents, customUIComponents, functionInputComponents } from './config/practiceComponents';
import { areProblemsEqual, getLevelData, handleMultipleChoiceKeyPress, generateNewProblem } from './utils/practiceUtils';
import PracticeHeader from './components/PracticeHeader';
import PracticeExercise from './components/PracticeExercise';
import LevelCompleteDialog from './components/LevelCompleteDialog';
import '../../styles/practice.css';

export default function Practice() {
  const { grade, topic, level } = useParams();
  const navigate = useNavigate();
  const [inputValue, setInputValue] = useState('');
  const [currentProblem, setCurrentProblem] = useState(null);
  const lastProblemRef = useRef(null);

  // Get the level data
  const levelData = getLevelData(grade, topic, level);

  const {
    loading,
    error,
    progress,
    key,
    handleAnswer,
    ANSWERS_TO_LEVEL_UP,
    levelComplete,
    bestTime,
    startTime,
    timeLeft,
    resetProgress
  } = usePracticeProgress(topic, levelData?.id, levelData?.timeLimit);

  // Generate new problem when key changes
  useEffect(() => {
    if (levelData && !customUIComponents.includes(levelData.exerciseType)) {
      const ExerciseComponent = exerciseComponents[levelData.exerciseType];
      if (ExerciseComponent) {
        let newProblem;
        let attempts = 0;
        const maxAttempts = 10; // Prevent infinite loops

        do {
          newProblem = generateNewProblem(ExerciseComponent, levelData, functionInputComponents);
          attempts++;
        } while (
          areProblemsEqual(newProblem, lastProblemRef.current) && 
          attempts < maxAttempts
        );

        setCurrentProblem(newProblem);
        lastProblemRef.current = newProblem;
      }
    }
  }, [key, levelData]);

  // Handle Enter key for dialog and multiple choice submission
  useEffect(() => {
    const handleKeyPress = (e) => {
      if (e.key === 'Enter') {
        if (levelComplete) {
          handleDialogClose();
        } else if (levelData?.exerciseType === 'MultipleChoicePractice' && inputValue) {
          e.preventDefault();
          const correctAnswer = currentProblem.problem.answer;
          handleAnswer(inputValue === correctAnswer ? 'correct' : inputValue, correctAnswer);
          setInputValue('');
        }
      }
    };

    document.addEventListener('keydown', handleKeyPress);
    return () => document.removeEventListener('keydown', handleKeyPress);
    // eslint-disable-next-line
  }, [levelComplete, levelData?.exerciseType, inputValue, currentProblem?.problem?.answer, handleAnswer]);

  // Handle number keys for multiple choice
  useEffect(() => {
    if (levelData?.exerciseType === 'MultipleChoicePractice' && currentProblem?.problem?.choices) {
      const handleNumberKey = (e) => handleMultipleChoiceKeyPress(e, currentProblem.problem.choices, setInputValue);
      document.addEventListener('keydown', handleNumberKey);
      return () => document.removeEventListener('keydown', handleNumberKey);
    }
  }, [levelData?.exerciseType, currentProblem?.problem?.choices]);

  const handleDialogClose = () => {
    navigate(`/grade/${grade}`);
  };

  const handlePracticeAgain = () => {
    resetProgress();
    lastProblemRef.current = null;
  };

  if (loading) {
    return <div className="practice-page">Loading...</div>;
  }

  if (!levelData) {
    return (
      <div className="practice-page">
        <Alert severity="error" className="error-message">Invalid level</Alert>
      </div>
    );
  }

  return (
    <div className="practice-page">
      <PracticeHeader
        grade={grade}
        topic={topic}
        level={level}
        progress={progress}
        ANSWERS_TO_LEVEL_UP={ANSWERS_TO_LEVEL_UP}
        levelComplete={levelComplete}
        bestTime={bestTime}
        startTime={startTime}
        timeLeft={timeLeft}
        timeLimit={levelData.timeLimit}
      />

      {error && <Alert severity="error" className="error-message">{error}</Alert>}

      <PracticeExercise
        levelData={levelData}
        currentProblem={currentProblem}
        inputValue={inputValue}
        setInputValue={setInputValue}
        handleAnswer={handleAnswer}
        level={level}
        progress={progress}
        lastProblemRef={lastProblemRef}
      />

      <LevelCompleteDialog
        open={levelComplete}
        level={level}
        onClose={handleDialogClose}
        onPracticeAgain={handlePracticeAgain}
      />
    </div>
  );
}

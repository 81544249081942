import { roll } from './utils';

export const addition = {
  "Add and Subtract up to 10": {
    levels: [
      { id: "xj2k9m4n", description: "Count Up by 1 to 10",  exerciseType: "CountingPractice", timeLimit: 60, inputs: { start: 1, increment: 1 } },
      { id: "p8v5q2r3", description: "Count Down by 1 from 10", exerciseType: "CountingPractice", timeLimit: 60, inputs: { start: 10, increment: -1 } },
      { id: "s8b3c7n1", description: "Adding 1", exerciseType: "OperationsPractice", timeLimit: 60, inputs: () => ({ 
        num1: roll(1, 8), 
        num2: 1, 
        operation: '+' 
      })},
      { id: "m5x9v2q6", description: "Subtracting 1", exerciseType: "OperationsPractice", timeLimit: 60, inputs: () => ({ 
        num1: roll(2, 8), 
        num2: 1, 
        operation: '-' 
      })},
      { id: "k4w8r3t7", description: "Adding 2", exerciseType: "OperationsPractice", timeLimit: 60, inputs: () => ({ 
        num1: roll(1, 7), 
        num2: 2, 
        operation: '+' 
      })},
      { id: "h1y5f9l2", description: "Subtracting 2", exerciseType: "OperationsPractice", timeLimit: 60, inputs: () => ({ 
        num1: roll(3, 9), 
        num2: 2, 
        operation: '-' 
      })},
      { id: "d6b2c8n4", description: "Adding 3", exerciseType: "OperationsPractice", timeLimit: 60, inputs: () => ({ 
        num1: roll(1, 6), 
        num2: 3, 
        operation: '+' 
      })},
      { id: "z9x3v7q1", description: "Subtracting 3", exerciseType: "OperationsPractice", timeLimit: 60, inputs: () => ({ 
        num1: roll(4, 9), 
        num2: 3, 
        operation: '-' 
      })},
      { id: "s8b3c7n2", description: "Adding to 1", exerciseType: "OperationsPractice", timeLimit: 60, inputs: () => ({ 
        num1: 1,
        num2: roll(2, 4),
        operation: '+' 
      })},
      { id: "t5w9r4h8", description: "Adding numbers up to 5", exerciseType: "OperationsPractice", timeLimit: 60, inputs: () => {
        const num1 = roll(1, 3);
        const num2 = roll(2, 5 - num1);
        return { num1, num2, operation: '+' };
      }},
      { id: "s7b4c9n3", description: "Subtracting from 5", exerciseType: "OperationsPractice", timeLimit: 60, inputs: () => ({ 
        num1: 5, 
        num2: roll(1, 4), 
        operation: '-' 
      })},
      { id: "d6b2c888", description: "Adding 4", exerciseType: "OperationsPractice", timeLimit: 60, inputs: () => ({ 
        num1: roll(2, 6), 
        num2: 4, 
        operation: '+' 
      })},
      { id: "s8b3c7n3", description: "Adding to 2 and 3", exerciseType: "OperationsPractice", timeLimit: 60, inputs: () => ({ 
        num1: roll(2, 3),
        num2: roll(5, 8),
        operation: '+' 
      })},
      { id: "g2y6f1l5", description: "Adding numbers up to 10", exerciseType: "OperationsPractice", timeLimit: 60, inputs: () => {
        const num1 = roll(1, 9);
        const num2 = roll(1, 10 - num1);
        return { num1, num2, operation: '+' };
      }},
      { id: "m1x8v2q7", description: "Subtracting numbers up to 10", exerciseType: "OperationsPractice", timeLimit: 60, inputs: () => {
        const num1 = roll(3, 9);
        const num2 = roll(2, num1 - 1);
        return { num1, num2, operation: '-' };
      }},
      { id: "f3k9p2m7", description: "Addition Fact Families to 5", exerciseType: "OperationsPractice", timeLimit: 60, inputs: () => {
        const part1 = roll(1, 4);
        const part2 = roll(1, 5 - part1);
        const positions = ['num1', 'num2', 'answer'];
        const missingPosition = positions[roll(0, 1)];
        return { 
          num1: part1, 
          num2: part2, 
          operation: '+',
          missingPosition 
        };
      }},
      { id: "j5r8t2k9", description: "Subtract Fact Families to 5", exerciseType: "OperationsPractice", timeLimit: 60, inputs: () => {
        const part1 = roll(1, 4);
        const part2 = roll(1, 5 - part1);
        const whole = part1 + part2;
        const positions = ['num1', 'num2', 'answer'];
        const missingPosition = positions[roll(0, 1)];
        return { 
          num1: whole, 
          num2: part1, 
          operation: '-',
          missingPosition 
        };
      }},
      { id: "h8n4q7w2", description: "Addition Fact Families to 10", exerciseType: "OperationsPractice", timeLimit: 60, inputs: () => {
        const part1 = roll(1, 9);
        const part2 = roll(1, 10 - part1);
        const positions = ['num1', 'num2', 'answer'];
        const missingPosition = positions[roll(0, 1)];
        return { 
          num1: part1, 
          num2: part2, 
          operation: '+',
          missingPosition 
        };
      }},
      { id: "w4y7h1n6", description: "Subtract Fact Families to 10", exerciseType: "OperationsPractice", timeLimit: 60, inputs: () => {
        const part1 = roll(1, 9);
        const part2 = roll(1, 10 - part1);
        const whole = part1 + part2;
        const positions = ['num1', 'num2', 'answer'];
        const missingPosition = positions[roll(0, 1)];
        return { 
          num1: whole, 
          num2: part1, 
          operation: '-',
          missingPosition 
        };
      }}
    ]
  }
};

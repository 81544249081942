import { roll, randomizeFactors } from './utils';

export const multiplication = {
    "Multiplication and Division" : {
        levels : [
            { id: "p9f3zzm4", description: "Multiply by 10", exerciseType: "OperationsPractice", timeLimit: 45, inputs: () => ({
              ...randomizeFactors(10, roll(2, 10)),
              operation: '*'
          })},
          { id: "d1v0t3n0", description: "Divide by 10", exerciseType: "OperationsPractice", timeLimit: 45, inputs: () => {
            const num2 = 10;
            const num1 = num2 * roll(3, 9);
            return { num1, num2, operation: '÷' };
          }},
            { id: "p9f3h7m4", description: "Multiply by 2", exerciseType: "OperationsPractice", timeLimit: 45, inputs: () => ({
              ...randomizeFactors(2, roll(2, 9)),
              operation: '*'
          })},
          { id: "d1v2t3n0", description: "Divide by 2", exerciseType: "OperationsPractice", timeLimit: 45, inputs: () => {
            const num2 = 2;
            const num1 = num2 * roll(3, 9);
            return { num1, num2, operation: '÷' };
          }},
            { id: "n5d8q2j6", description: "Count by 5", exerciseType: "CountingPractice", timeLimit: 30, inputs: { start: 5, increment: 5 } },
            { id: "n5rrq2j6", description: "Count Down by 5", exerciseType: "CountingPractice", timeLimit: 30, inputs: { start: 50, increment: -5 } },
            { id: "p9fzz7m4", description: "Multiply by 5", exerciseType: "OperationsPractice", timeLimit: 45, inputs: () => ({
              ...randomizeFactors(5, roll(3, 9)),
              operation: '*'
          })},
          { id: "d1v5t3n0", description: "Divide by 5", exerciseType: "OperationsPractice", timeLimit: 45, inputs: () => {
            const num2 = 5;
            const num1 = num2 * roll(3, 9);
            return { num1, num2, operation: '÷' };
          }},
          { id: "r8w2x6m4", description: "Multiplication with 2, 5, or 10", exerciseType: "OperationsPractice", timeLimit: 45, inputs: () => ({
            ...randomizeFactors([2,5,10][roll(0,2)], roll(3, 8)),
            operation: '*'
          })},
          { id: "d1v25t3n", description: "Division with 2, 5, or 10", exerciseType: "OperationsPractice", timeLimit: 45, inputs: () => {
            const num2 = [2, 5, 10][roll(0, 2)];
            const num1 = num2 * roll(3, 9);
            return { num1, num2, operation: '÷' };
          }},
          { id: "b5d8q2j6", description: "Count by 3", exerciseType: "CountingPractice", timeLimit: 30, inputs: { start: 3, increment: 3 } },
          { id: "b5rrq2j6", description: "Count Down by 3", exerciseType: "CountingPractice", timeLimit: 30, inputs: { start: 30, increment: -3 } },
          { id: "b9f3h7m4", description: "Multiply by 3", exerciseType: "OperationsPractice", timeLimit: 45, inputs: () => ({
            ...randomizeFactors(3, roll(3, 9)),
            operation: '*'
          })},
          { id: "d1v3t3n0", description: "Divide by 3", exerciseType: "OperationsPractice", timeLimit: 45, inputs: () => {
            const num2 = 3;
            const num1 = num2 * roll(3, 9);
            return { num1, num2, operation: '÷' };
          }},
          { id: "c5d8q2j6", description: "Count by 4", exerciseType: "CountingPractice", timeLimit: 30, inputs: { start: 4, increment: 4 } },
          { id: "c5rrq2j6", description: "Count Down by 4", exerciseType: "CountingPractice", timeLimit: 30, inputs: { start: 40, increment: -4 } },
          { id: "c9f3h7m4", description: "Multiply by 4", exerciseType: "OperationsPractice", timeLimit: 45, inputs: () => ({
            ...randomizeFactors(4, roll(3, 9)),
            operation: '*'
          })},
          { id: "d1v4t3n0", description: "Divide by 4", exerciseType: "OperationsPractice", timeLimit: 45, inputs: () => {
            const num2 = 4;
            const num1 = num2 * roll(3, 9);
            return { num1, num2, operation: '÷' };
          }},
          { id: "g8w2x6m4", description: "Multiplication with 3 or 4", exerciseType: "OperationsPractice", timeLimit: 45, inputs: () => ({
            ...randomizeFactors(roll(3,4), roll(4, 9)),
            operation: '*'
          })},
          { id: "d1v34t3n", description: "Division with 3 or 4", exerciseType: "OperationsPractice", timeLimit: 45, inputs: () => {
            const num2 = [3, 4][roll(0, 1)];
            const num1 = num2 * roll(3, 9);
            return { num1, num2, operation: '÷' };
          }},
          { id: "s5rrr2w6", description: "Missing Factor with 2,3,4, or 5", exerciseType: "OperationsPractice", timeLimit: 45, inputs: () => {
            const factors = randomizeFactors(roll(2,5), roll(3, 9));
            return {
                ...factors,
                operation: '*',
                missingPosition: roll(0, 1) === 0 ? 'num1' : 'num2'
            };
        }},
        { id: "d1vm1s34", description: "Missing Dividend with 2,3,4, or 5", exerciseType: "OperationsPractice", timeLimit: 45, inputs: () => {
          const num2 = roll(2, 5);
          const num1 = num2 * roll(3, 9);
          return {
            num1,
            num2,
            operation: '÷',
            missingPosition: 'num1'
          };
        }},
          { id: "d5d8q2j6", description: "Count by 6", exerciseType: "CountingPractice", timeLimit: 30, inputs: { start: 6, increment: 6 } },
          { id: "d5rrq2j6", description: "Count Down by 6", exerciseType: "CountingPractice", timeLimit: 30, inputs: { start: 60, increment: -6 } },
          { id: "d9f3h7m4", description: "Multiply by 6", exerciseType: "OperationsPractice", timeLimit: 45, inputs: () => ({
            ...randomizeFactors(6, roll(3, 9)),
            operation: '*'
          })},
          { id: "d1v6t3n0", description: "Divide by 6", exerciseType: "OperationsPractice", timeLimit: 45, inputs: () => {
            const num2 = 6;
            const num1 = num2 * roll(3, 9);
            return { num1, num2, operation: '÷' };
          }},
          { id: "e5d8q2j6", description: "Count by 9", exerciseType: "CountingPractice", timeLimit: 30, inputs: { start: 9, increment: 9 } },
          { id: "e5rrq2j6", description: "Count Down by 9", exerciseType: "CountingPractice", timeLimit: 30, inputs: { start: 90, increment: -9 } },
          { id: "e9f3h7m4", description: "Multiply by 9", exerciseType: "OperationsPractice", timeLimit: 45, inputs: () => ({
            ...randomizeFactors(9, roll(3, 9)),
            operation: '*'
          })},
          { id: "d1v9t3n0", description: "Divide by 9", exerciseType: "OperationsPractice", timeLimit: 45, inputs: () => {
            const num2 = 9;
            const num1 = num2 * roll(3, 9);
            return { num1, num2, operation: '÷' };
          }},
            { id: "f5d8q2j6", description: "Count by 8", exerciseType: "CountingPractice", timeLimit: 30, inputs: { start: 8, increment: 8 } },
            { id: "f5rrq2j6", description: "Count Down by 8", exerciseType: "CountingPractice", timeLimit: 30, inputs: { start: 80, increment: -8 } },
            { id: "f9f3h7m4", description: "Multiply by 8", exerciseType: "OperationsPractice", timeLimit: 45, inputs: () => ({
              ...randomizeFactors(8, roll(3, 9)),
              operation: '*'
          })},
          { id: "d1v8t3n0", description: "Divide by 8", exerciseType: "OperationsPractice", timeLimit: 45, inputs: () => {
            const num2 = 8;
            const num1 = num2 * roll(3, 9);
            return { num1, num2, operation: '÷' };
          }},
          { id: "g5d8q2j6", description: "Count by 7", exerciseType: "CountingPractice", timeLimit: 30, inputs: { start: 7, increment: 7 } },
          { id: "g5rrq2j6", description: "Count Down by 7", exerciseType: "CountingPractice", timeLimit: 30, inputs: { start: 70, increment: -7 } },
          { id: "g9f3h7m4", description: "Multiply by 7", exerciseType: "OperationsPractice", timeLimit: 45, inputs: () => ({
            ...randomizeFactors(7, roll(3, 9)),
            operation: '*'
          })},
          { id: "d1v7t3n0", description: "Divide by 7", exerciseType: "OperationsPractice", timeLimit: 45, inputs: () => {
            const num2 = 7;
            const num1 = num2 * roll(3, 9);
            return { num1, num2, operation: '÷' };
          }},
          { id: "y8y2x6m4", description: "Multiplication by 6,7,8 or 9", exerciseType: "OperationsPractice", timeLimit: 45, inputs: () => ({
            ...randomizeFactors(roll(6,9), roll(3, 9)),
            operation: '*'
          })},
          { id: "d1v6789n", description: "Division with 6,7,8 or 9", exerciseType: "OperationsPractice", timeLimit: 45, inputs: () => {
            const num2 = roll(6, 9);
            const num1 = num2 * roll(3, 9);
            return { num1, num2, operation: '÷' };
          }},
            { id: "s5g8k2w6", description: "Missing Factor with 6,7,8 or 9", exerciseType: "OperationsPractice", timeLimit: 45, inputs: () => {
              const factors = randomizeFactors(roll(6,9), roll(3, 7));
              return {
                  ...factors,
                  operation: '*',
                  missingPosition: roll(0, 1) === 0 ? 'num1' : 'num2'
              };
          }},
          { id: "d1vm6789", description: "Missing Dividend with 6,7,8 or 9", exerciseType: "OperationsPractice", timeLimit: 45, inputs: () => {
            const num2 = roll(6, 9);
            const num1 = num2 * roll(3, 9);
            return {
              num1,
              num2,
              operation: '÷',
              missingPosition: 'num1'
            };
          }}
        ]
    }
};

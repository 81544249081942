import CountingPractice from '../practices/CountingPractice';
import OperationsPractice from '../practices/OperationsPractice';
import HalvesPractice from '../practices/HalvesPractice';
import PlaceValuePractice from '../practices/PlaceValuePractice';
import TimePractice from '../practices/TimePractice';
import MoneyPractice from '../practices/MoneyPractice';
import VerticalOperationsPractice from '../practices/VerticalOperationsPractice';
import MultipleChoicePractice from '../practices/MultipleChoicePractice';
import ConversionPractice from '../practices/ConversionPractice';
import BalancedEquationPractice from '../practices/BalancedEquationPractice';
import NamingFractionsPractice from '../practices/NamingFractionsPractice';
import EquivalentExpressionPractice from '../practices/EquivalentExpressionPractice';
import TwoDigitMultiplicationPractice from '../practices/TwoDigitMultiplicationPractice';
import FactorPractice from '../practices/FactorPractice';
import DivisionWithRemainderPractice from '../practices/DivisionWithRemainderPractice';
import LongDivisionPractice from '../practices/LongDivisionPractice';
import ComparingFractionsPractice from '../practices/ComparingFractionsPractice';
import EquivalentFractionsPractice from '../practices/EquivalentFractionsPractice';
import ComparingDecimalsPractice from '../practices/ComparingDecimalsPractice';

// Map of exercise types to components
export const exerciseComponents = {
  CountingPractice,
  OperationsPractice,
  HalvesPractice,
  PlaceValuePractice,
  TimePractice,
  MoneyPractice,
  VerticalOperationsPractice,
  MultipleChoicePractice,
  ConversionPractice,
  BalancedEquationPractice,
  NamingFractionsPractice,
  EquivalentExpressionPractice,
  TwoDigitMultiplicationPractice,
  FactorPractice,
  DivisionWithRemainderPractice,
  LongDivisionPractice,
  ComparingFractionsPractice,
  EquivalentFractionsPractice,
  ComparingDecimalsPractice
};

// Components that have their own UI
export const customUIComponents = [
  'CountingPractice', 
  'TimePractice', 
  'VerticalOperationsPractice', 
  'MoneyPractice',
  'NamingFractionsPractice',
  'EquivalentExpressionPractice',
  'TwoDigitMultiplicationPractice',
  'FactorPractice',
  'DivisionWithRemainderPractice',
  'LongDivisionPractice',
  'ComparingFractionsPractice',
  'EquivalentFractionsPractice',
  'ComparingDecimalsPractice'
];

// Components that use function-based inputs
export const functionInputComponents = [
  'OperationsPractice', 
  'HalvesPractice', 
  'MultipleChoicePractice', 
  'ConversionPractice', 
  'TimePractice',
  'PlaceValuePractice',
  'BalancedEquationPractice',
  'NamingFractionsPractice',
  'EquivalentExpressionPractice',
  'TwoDigitMultiplicationPractice',
  'VerticalOperationsPractice',
  'DivisionWithRemainderPractice',
  'LongDivisionPractice',
  'ComparingFractionsPractice',
  'EquivalentFractionsPractice',
  'ComparingDecimalsPractice'
];

// Components that use inline input
export const inlineInputComponents = [
  'OperationsPractice', 
  'ConversionPractice', 
  'HalvesPractice', 
  'BalancedEquationPractice'
];

import { Box, Container, CircularProgress } from '@mui/material';
import { useState, useEffect } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../firebase';

export default function Benefits() {
  const [stats, setStats] = useState({
    students: 0,
    teachers: 0,
    totalLevels: 0
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchStats = async () => {
      try {
        console.log("Starting to fetch users...");
        const usersSnapshot = await getDocs(collection(db, 'users'));
        console.log("Total users found:", usersSnapshot.size);

        let studentCount = 0;
        let teacherCount = 0;
        let totalLevels = 0;

        usersSnapshot.forEach(doc => {
          const userData = doc.data();
          console.log("Processing user:", userData.role);

          if (userData.role === 'student') {
            studentCount++;
            
            // Count completed levels from levelBestTimes (new format)
            const levelBestTimes = userData.levelBestTimes || {};
            Object.keys(levelBestTimes).forEach(levelId => {
              totalLevels += 1;
            });

            // Count completed levels from levelCompletions (older format)
            const levelCompletions = userData.levelCompletions || {};
            Object.entries(levelCompletions).forEach(([levelId, completionCount]) => {
              // Only count if not already counted in levelBestTimes
              if (typeof completionCount === 'number' && !levelBestTimes[levelId]) {
                totalLevels += completionCount;
              }
            });

            // Add any levels from completedLevels array that aren't in levelBestTimes or levelCompletions
            const completedLevels = userData.completedLevels || [];
            completedLevels.forEach(levelId => {
              if (!levelBestTimes[levelId] && !levelCompletions[levelId]) {
                totalLevels += 1;
              }
            });
          } else if (userData.role === 'teacher') {
            teacherCount++;
          }
        });

        console.log("Processed counts:", {
          students: studentCount,
          teachers: teacherCount,
          totalLevels: totalLevels
        });

        setStats({
          students: studentCount,
          teachers: teacherCount,
          totalLevels: totalLevels
        });
      } catch (error) {
        console.error("Error fetching stats:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchStats();
  }, []);

  return (
    <section className="benefits-section">
      <Container maxWidth="lg">
        <Box className="section-title-wrapper">
          <h2 className="section-title">Active Users</h2>
        </Box>
        
            <div className="benefits-image">
              <div className="benefits-stats">
                {loading ? (
                  <div className="stats-loading">
                    <CircularProgress />
                  </div>
                ) : (
                  <>
                    <div className="stat-item">
                      <span className="stat-number">{stats.teachers}</span>
                      <span className="stat-label">Active Teachers</span>
                    </div>
                    <div className="stat-item">
                      <span className="stat-number">{stats.students}</span>
                      <span className="stat-label">Active Students</span>
                    </div>
                    <div className="stat-item">
                      <span className="stat-number">{stats.totalLevels}</span>
                      <span className="stat-label">Completed Practices</span>
                    </div>
                  </>
                )}
              </div>
            </div>
      </Container>
    </section>
  );
}

export const exponents = {
  "Exponents": {
    levels: [
      {
        id: "e5sqas1",
        description: "Exponents of 10",
        exerciseType: "OperationsPractice",
        timeLimit: 90,
        inputs: () => ({
          num1: 10,
          num2: Math.floor(Math.random() * 5) + 2,
          operation: "^",
          missingPosition: "result"
        })
      },
      {
        id: "e5sqba1",
        description: "Exponents of 2",
        exerciseType: "OperationsPractice",
        timeLimit: 90,
        inputs: () => ({
          num1: 2,
          num2: Math.floor(Math.random() * 5) + 2,
          operation: "^",
          missingPosition: "result"
        })
      },
      {
        id: "e5sadt1",
        description: "Square Numbers (2² to 9²)",
        exerciseType: "OperationsPractice",
        timeLimit: 90,
        inputs: () => ({
          num1: Math.floor(Math.random() * 7) + 3, // 2-9
          num2: 2,
          operation: "^",
          missingPosition: "result"
        })
      },
      {
        id: "e5cb1t1",
        description:"Cube Numbers (2³ to 9³)",
        exerciseType: "OperationsPractice",
        timeLimit: 120,
        inputs: () => ({
          num1: Math.floor(Math.random() * 8) + 2,
          num2: 3,
          operation: "^",
          missingPosition: "result"
        })
      },
    ]
  }
};

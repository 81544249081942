import { useAuth } from '../../../contexts/AuthContext';
import useClassManagement from '../../../hooks/useClassManagement';
import {
  Container,
  Paper,
  Typography,
  Box,
  Alert,
  CircularProgress,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Select,
  MenuItem,
  FormControl,
  InputLabel
} from '@mui/material';
import ClassList from '../teacher/ClassList';
import StudentTable from '../teacher/StudentTable';
import { useState } from 'react';
import '../../../styles/teacherdashboard.css';
import gradeContent from '../../../components/grades/gradeContent';

const TIME_WINDOWS = {
  hour: { label: 'Last Hour', value: 60 * 60 * 1000 },
  day: { label: 'Last 24 Hours', value: 24 * 60 * 60 * 1000 },
  week: { label: 'Last 7 Days', value: 7 * 24 * 60 * 60 * 1000 },
  month: { label: 'Last 30 Days', value: 30 * 24 * 60 * 60 * 1000 },
  year: { label: 'Last Year', value: 365 * 24 * 60 * 60 * 1000 },
  all: { label: 'All Time', value: Number.MAX_SAFE_INTEGER }
};

export default function TeacherDashboard() {
  const { userProfile } = useAuth();
  const {
    classes,
    loading,
    error,
    selectedClass,
    classStudents,
    handleCreateClass,
    handleDeleteClass,
    loadClassStudents
  } = useClassManagement();

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [selectedGrade, setSelectedGrade] = useState('');
  const [selectedTopic, setSelectedTopic] = useState('');
  const [timeWindow, setTimeWindow] = useState('week');

  const handleDeleteConfirm = async () => {
    const success = await handleDeleteClass(selectedClass.id);
    if (success) {
      setDeleteDialogOpen(false);
    }
  };

  if (!userProfile) {
    return (
      <Container maxWidth="sm">
        <Box className="loading-container">
          <CircularProgress />
        </Box>
      </Container>
    );
  }

  if (userProfile.role !== 'teacher') {
    return (
      <Container maxWidth="sm">
        <Alert severity="error" className="access-denied-alert">
          Access denied. This dashboard is only for teachers.
        </Alert>
      </Container>
    );
  }

  return (
    <Container maxWidth="lg">
      <Box className="dashboard-container">
        <Paper className="dashboard-paper">
          <Box className="dashboard-header">
            <Typography className="dashboard-title">
              Teacher Dashboard
            </Typography>
          </Box>

          <ClassList
            classes={classes}
            loading={loading}
            error={error}
            selectedClass={selectedClass}
            onCreateClass={handleCreateClass}
            onSelectClass={loadClassStudents}
          />

          {selectedClass && (
            <Box className="selected-class-container">
              <Box className="class-header">
                <Typography className="selected-class-title">
                  {selectedClass.name}
                </Typography>
                <Box sx={{ display: 'flex', gap: 2 }}>
                  {!selectedTopic && (
                    <FormControl className="grade-select">
                      <InputLabel>Activity Window</InputLabel>
                      <Select
                        value={timeWindow}
                        label="Activity Window"
                        onChange={(e) => setTimeWindow(e.target.value)}
                      >
                        {Object.entries(TIME_WINDOWS).map(([key, { label }]) => (
                          <MenuItem key={key} value={key}>
                            {label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}

                  <FormControl className="grade-select">
                    <InputLabel>Grade</InputLabel>
                    <Select
                      value={selectedGrade}
                      label="Grade"
                      onChange={(e) => {
                        setSelectedGrade(e.target.value);
                        setSelectedTopic(''); // Reset topic when grade changes
                      }}
                    >
                      <MenuItem value="">All Grades</MenuItem>
                      {Object.keys(gradeContent).map(grade => (
                        <MenuItem key={grade} value={parseInt(grade)}>
                          Grade {grade}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <FormControl className="grade-select">
                    <InputLabel>Topic</InputLabel>
                    <Select
                      value={selectedTopic}
                      label="Topic"
                      onChange={(e) => setSelectedTopic(e.target.value)}
                      disabled={!selectedGrade}
                    >
                      <MenuItem value="">All Topics</MenuItem>
                      {selectedGrade && Object.keys(gradeContent[selectedGrade] || {}).map(topic => (
                        <MenuItem key={topic} value={topic}>
                          {topic}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              </Box>

              <StudentTable 
                students={classStudents} 
                selectedGrade={selectedGrade}
                selectedTopic={selectedTopic}
                gradeContent={gradeContent}
                timeWindow={TIME_WINDOWS[timeWindow]}
              />

              <Box className="delete-class-container">
                <Button
                  variant="contained"
                  className="delete-class-button"
                  onClick={() => setDeleteDialogOpen(true)}
                >
                  Delete Class
                </Button>
              </Box>
            </Box>
          )}

          <Dialog
            open={deleteDialogOpen}
            onClose={() => setDeleteDialogOpen(false)}
            className="delete-class-dialog"
          >
            <DialogTitle>Delete Class</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Are you sure you want to delete {selectedClass?.name}? This action is permanent and cannot be undone. All student data associated with this class will be removed.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => setDeleteDialogOpen(false)}
                className="cancel-button"
              >
                Cancel
              </Button>
              <Button
                onClick={handleDeleteConfirm}
                className="confirm-delete-button"
                variant="contained"
              >
                Delete Class
              </Button>
            </DialogActions>
          </Dialog>
        </Paper>
      </Box>
    </Container>
  );
}

import { roll } from './utils';

export const division = {
  "Multidigit Division": {
    levels: [
      { id: "d4r1n225", description: "Division by 2 or 5 with remainders", exerciseType: "DivisionWithRemainderPractice", timeLimit: 60, inputs: () => {
        const num2 = [2,5][roll(0,1)];
        const num1 = roll(num2*2+1,num2*10-1);
        return { num1, num2 };
      }},
      { id: "d4r2n4k3", description: "Division by 3 or 4 with remainders", exerciseType: "DivisionWithRemainderPractice", timeLimit: 60, inputs: () => {
        const num2 = [3,4][roll(0,1)];
        const num1 = roll(num2*2+1,num2*10-1);
        return { num1, num2 };
      }},
      { id: "d4r2345", description: "Division by 2, 3, 4, or 5 with remainders", exerciseType: "DivisionWithRemainderPractice", timeLimit: 60, inputs: () => {
        const num2 = roll(2,5);
        const num1 = roll(num2*2+1,num2*10-1);
        return { num1, num2 };
      }},
      { id: "d4r2n4k6", description: "Division by 6 or 7 with remainders", exerciseType: "DivisionWithRemainderPractice", timeLimit: 60, inputs: () => {
        const num2 = roll(6,7);
        const num1 = roll(num2*2+1,num2*10-1);
        return { num1, num2 };
      }},
      { id: "d4r2n4k8", description: "Division by 8 or 9 with remainders", exerciseType: "DivisionWithRemainderPractice", timeLimit: 60, inputs: () => {
        const num2 = roll(8,9);
        const num1 = roll(num2*2+1,num2*10-1);
        return { num1, num2 };
      }},
      { id: "d4r6789", description: "Division by 6, 7, 8, or 9 with remainders", exerciseType: "DivisionWithRemainderPractice", timeLimit: 60, inputs: () => {
        const num2 = roll(6,9);
        const num1 = roll(num2*2+1,num2*10-1);
        return { num1, num2 };
      }},
      { id: "d4l25", description: "Two Digit Long Division by 2 or 5", exerciseType: "LongDivisionPractice", timeLimit: 600, inputs: () => {
        const num2 = [2,5][roll(0,1)];
        const num1 = roll(12*num2,99);;
        return { num1, num2 };
      }},
      { id: "d4l34", description: "Two Digit Long Division by 3 or 4", exerciseType: "LongDivisionPractice", timeLimit: 600, inputs: () => {
        const num2 = roll(3,4);
        const num1 = roll(12*num2,99);;
        return { num1, num2 };
      }},
      { id: "d43l2345", description: "Three Digit Long Division by 2, 3, 4, or 5", exerciseType: "LongDivisionPractice", timeLimit: 600, inputs: () => {
        const num2 = roll(2, 5);
        const num1 = roll(51*num2,999);;
        return { num1, num2 };
      }},
      { id: "d43l67", description: "Three Digit Long Division by 6, 7, 8, or 9", exerciseType: "LongDivisionPractice", timeLimit: 600, inputs: () => {
        const num2 = roll(6, 9);
        const num1 = roll(111,999);;
        return { num1, num2 };
      }},
    ]
  }
};

import { roll } from './utils';

export const multiplication = {
  "Multidigit Multiplication and Division": {
    levels: [
      { 
        id: "j6zgh8f4", 
        description: "Three Digits Times Two Digits",
        exerciseType: "VerticalOperationsPractice", 
        timeLimit: 600,
        inputs: () => {
          const num1 = roll(3,9)*100 + roll(3,9)*10+roll(3,9); 
          const num2 = roll(3,9)*10+roll(3,9); 
          return {
            num1,
            num2,
            operation: '*'
          };
        }
      },
    ]
  }
};

import React from 'react';
import { Box } from '@mui/material';

export default function AnalogClock({ hour, minute }) {
  // Convert hour to 12-hour format
  hour = hour % 12;
  
  // Calculate angles for hour and minute hands
  const hourAngle = ((hour % 12) + minute / 60) * 30; // 360° / 12 = 30° per hour
  const minuteAngle = minute * 6; // 360° / 60 = 6° per minute

  return (
    <Box
      sx={{
        width: '200px',
        height: '200px',
        borderRadius: '50%',
        border: '4px solid #333',
        position: 'relative',
        backgroundColor: 'white',
        margin: '20px auto'
      }}
    >
      {/* Minute tick marks */}
      {[...Array(60)].map((_, index) => (
        <Box
          key={`tick-${index}`}
          sx={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            textAlign: 'center',
            transform: `rotate(${index * 6}deg)`, // 360° / 60 = 6° per minute
            '&::after': {
              content: '""',
              display: index % 5 === 0 ? 'none' : 'block', // Don't show tick marks where numbers are
              position: 'absolute',
              width: '1px',
              height: '8px',
              backgroundColor: '#999',
              left: 'calc(50% - 0.5px)',
              top: '2px'
            }
          }}
        />
      ))}

      {/* 5-minute interval tick marks */}
      {[...Array(12)].map((_, index) => (
        <Box
          key={`five-min-${index}`}
          sx={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            textAlign: 'center',
            transform: `rotate(${index * 30}deg)`, // 360° / 12 = 30° per interval
            '&::after': {
              content: '""',
              display: 'block',
              position: 'absolute',
              width: '2px',
              height: '12px',
              backgroundColor: '#333',
              left: 'calc(50% - 1px)',
              top: '2px'
            }
          }}
        />
      ))}

      {/* Clock numbers */}
      {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((num) => (
        <Box
          key={num}
          sx={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            textAlign: 'center',
            transform: `rotate(${num * 30}deg)`,
            '& > div': {
              transform: `rotate(-${num * 30}deg)`,
              color: '#333',
              fontSize: '1.2rem',
              fontWeight: 'bold',
              position: 'absolute',
              width: '20px',
              left: 'calc(50% - 10px)',
              top: '10px'
            }
          }}
        >
          <div>{num}</div>
        </Box>
      ))}

      {/* Hour hand */}
      <Box
        sx={{
          position: 'absolute',
          width: '6px',
          height: '60px',
          backgroundColor: '#333',
          top: 'calc(50% - 60px)',
          left: 'calc(50% - 3px)',
          transformOrigin: 'bottom center',
          transform: `rotate(${hourAngle}deg)`,
          borderRadius: '3px'
        }}
      />

      {/* Minute hand */}
      <Box
        sx={{
          position: 'absolute',
          width: '4px',
          height: '80px',
          backgroundColor: '#666',
          top: 'calc(50% - 80px)',
          left: 'calc(50% - 2px)',
          transformOrigin: 'bottom center',
          transform: `rotate(${minuteAngle}deg)`,
          borderRadius: '2px'
        }}
      />

      {/* Center dot */}
      <Box
        sx={{
          position: 'absolute',
          width: '12px',
          height: '12px',
          backgroundColor: '#333',
          borderRadius: '50%',
          top: 'calc(50% - 6px)',
          left: 'calc(50% - 6px)'
        }}
      />
    </Box>
  );
}

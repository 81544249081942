import { dataMeasurements } from './grade4/dataMeasurements';
import { decimals } from './grade4/decimals';
import { division } from './grade4/division';
import { fractions } from './grade4/fractions';
import { geometry } from './grade4/geometry';
import { multiplesFactors } from './grade4/multiplesFactors';
import { multiplication } from './grade4/multiplication';
import { placeValue } from './grade4/placeValue';

const grade4Content = {
    ...placeValue,
    ...multiplication,
    ...division,
    ...multiplesFactors,
    ...fractions,
    ...decimals,
    ...geometry,
    ...dataMeasurements,
};

export default grade4Content;

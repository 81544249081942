import React, { useState } from 'react';

const EquivalentFractionsPractice = React.memo(({ inputs: inputsFn, onAnswer }) => {
  const [inputValue, setInputValue] = useState('');
  const [problem, setProblem] = useState(() => {
    const { num1, den1, given, blank } = typeof inputsFn === 'function' ? inputsFn() : inputsFn;
    return { num1, den1, given, blank };
  });

  // Handle number input validation
  const handleInputChange = (e) => {
    const value = e.target.value;
    if (/^\d*$/.test(value)) {
      setInputValue(value);
    }
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    const numAnswer = parseInt(inputValue);
    
    let isCorrect = false;
    if (!isNaN(numAnswer) && numAnswer > 0) {
      if (problem.blank === 'numerator') {
        isCorrect = problem.num1 * problem.given === numAnswer * problem.den1;
      } else {
        isCorrect = problem.num1 * numAnswer === problem.given * problem.den1;
      }
    }

    onAnswer(
      isCorrect ? 'correct' : inputValue,
      problem.blank === 'numerator' 
        ? (problem.num1 * problem.given) / problem.den1 
        : (problem.den1 * problem.given) / problem.num1
    );

    // Generate new problem after every submission
    const { num1, den1, given, blank } = typeof inputsFn === 'function' ? inputsFn() : inputsFn;
    setProblem({ num1, den1, given, blank });
    setInputValue('');
  };

  const fractionStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  };

  const numberStyle = {
    fontSize: '2rem',
    minWidth: '40px',
    textAlign: 'center'
  };

  const lineStyle = {
    borderTop: '2px solid var(--text-primary)',
    width: '100%',
    minWidth: '40px',
    textAlign: 'center',
    paddingTop: '3px'
  };

  return (
    <form onSubmit={handleSubmit} className="content-box">
      <div style={{ display: 'flex', alignItems: 'center', gap: '2rem', justifyContent: 'center' }}>
        <div style={fractionStyle}>
          <div style={numberStyle}>{problem.num1}</div>
          <div style={{ ...lineStyle, fontSize: '2rem' }}>{problem.den1}</div>
        </div>
        <div style={{ fontSize: '2rem' }}>=</div>
        <div style={fractionStyle}>
          {problem.blank === 'numerator' ? (
            <>
              <div className="fraction-input" style={{ border: 'none', padding: 0 }}>
                <input
                  type="text"
                  value={inputValue}
                  onChange={handleInputChange}
                  style={{ width: '40px', fontSize: '2rem' }}
                  autoFocus
                />
              </div>
              <div style={{ ...lineStyle, fontSize: '2rem' }}>{problem.given}</div>
            </>
          ) : (
            <>
              <div style={numberStyle}>{problem.given}</div>
              <div style={lineStyle}>
                <div className="fraction-input" style={{ border: 'none', padding: 0 }}>
                  <input
                    type="text"
                    value={inputValue}
                    onChange={handleInputChange}
                    style={{ width: '40px', fontSize: '2rem' }}
                    autoFocus
                  />
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </form>
  );
});

export default EquivalentFractionsPractice;

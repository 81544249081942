import React, { useState, useEffect, useRef } from 'react';
import { Typography, TextField } from '@mui/material';

const TwoDigitMultiplicationPractice = ({ inputs: inputsFn, onAnswer }) => {
  const [answers, setAnswers] = useState({
    line1First: '',
    line1Second: '',
    line2First: '',
    line2Second: '',
    line2Third: '',
    line2Fourth: '',
    line3First: '',
    line3Second: '',
    line3Third: '',
    line3Fourth: '',
    lineCombined1: '',
    lineCombined2: '',
    line4: ''
  });
  const [problem, setProblem] = useState(null);
  const firstInputRef = useRef(null);

  useEffect(() => {
    if (!problem) {
      const newInputs = typeof inputsFn === 'function' ? inputsFn() : inputsFn;
      setProblem(newInputs);
    }
    // eslint-disable-next-line
  }, [inputsFn]);

  const handleInputChange = (value, field) => {
    if (/^-?\d*$/.test(value)) {
      setAnswers(prev => ({ ...prev, [field]: value }));
    }
  };

  const checkAnswers = () => {
    if (!problem) return;

    const { number, secondNumber, split } = problem;
    const { firstPart: tens2, secondPart: ones2 } = split;
    const { tens: tens1, ones: ones1 } = split.firstNumberParts;
    
    if (!answers.line1First || !answers.line1Second ||
        !answers.line2First || !answers.line2Second || !answers.line2Third || !answers.line2Fourth ||
        !answers.line3First || !answers.line3Second || !answers.line3Third || !answers.line3Fourth ||
        !answers.lineCombined1 || !answers.lineCombined2 ||
        !answers.line4) return;

    // Calculate partial products in the correct order:
    // First multiply both parts by tens, then both parts by ones
    const tensTimesTens = (tens1) * (tens2);    // 70 × 20
    const onesTimesTens = ones1 * (tens2);      // 2 × 20
    const tensTimesOnes = (tens1) * ones2;      // 70 × 5
    const onesTimesOnes = ones1 * ones2;        // 2 × 5
    const firstSum = tensTimesTens + onesTimesTens;  // (70 × 20 + 2 × 20)
    const secondSum = tensTimesOnes + onesTimesOnes; // (70 × 5 + 2 × 5)
    const total = number * secondNumber;

    const isCorrect = 
      answers.line1First === tens2.toString() &&
      answers.line1Second === ones2.toString() &&
      answers.line2First === tens2.toString() &&
      answers.line2Second === tens2.toString() &&
      answers.line2Third === ones2.toString() &&
      answers.line2Fourth === ones2.toString() &&
      answers.line3First === tensTimesTens.toString() &&
      answers.line3Second === onesTimesTens.toString() &&
      answers.line3Third === tensTimesOnes.toString() &&
      answers.line3Fourth === onesTimesOnes.toString() &&
      answers.lineCombined1 === firstSum.toString() &&
      answers.lineCombined2 === secondSum.toString() &&
      answers.line4 === total.toString();

    if (isCorrect) {
      const newInputs = typeof inputsFn === 'function' ? inputsFn() : inputsFn;
      setProblem(newInputs);
      setAnswers({
        line1First: '',
        line1Second: '',
        line2First: '',
        line2Second: '',
        line2Third: '',
        line2Fourth: '',
        line3First: '',
        line3Second: '',
        line3Third: '',
        line3Fourth: '',
        lineCombined1: '',
        lineCombined2: '',
        line4: ''
      });
      onAnswer('correct');
      firstInputRef.current?.focus();
    } else {
      onAnswer(
        `${answers.line1First},${answers.line1Second},${answers.line2First},${answers.line2Second},${answers.line2Third},${answers.line2Fourth},${answers.line3First},${answers.line3Second},${answers.line3Third},${answers.line3Fourth},${answers.lineCombined1},${answers.lineCombined2},${answers.line4}`,
        `${tens2},${ones2},${tens2},${tens2},${ones2},${ones2},${tensTimesTens},${onesTimesTens},${tensTimesOnes},${onesTimesOnes},${firstSum},${secondSum},${total}`
      );
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    checkAnswers();
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      checkAnswers();
    }
  };

  if (!problem) return null;

  const { number, secondNumber, hints = [] } = problem;
  const { tens: tens1, ones: ones1 } = problem.split.firstNumberParts;

  const inputWidth = '50px';
  const inputContainerStyle = {
    display: 'inline-flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: inputWidth,
    margin: '0 12px'
  };

  const expressionStyle = {
    color: 'var(--text-primary)',
    fontSize: '1.1rem',
    display: 'inline-block'
  };

  const lineStyle = {
    display: 'flex',
    alignItems: 'center',
    minHeight: '60px',
    marginBottom: '8px'
  };

  const inputStyle = {
    width: inputWidth,
    margin: '0 12px'
  };

  return (
    <div className="content-box">
      <Typography className="practice-title">
        Break down the multiplication problem
      </Typography>
      <form onSubmit={handleSubmit} className="expression-form">
        {/* Line 1: Breaking down first number */}
        <div style={lineStyle}>
          <Typography variant="h5" component="span" style={expressionStyle}>
            {`${number} × ${secondNumber} = (${number} × `}
          </Typography>
          <div style={inputContainerStyle}>
            <TextField
              className="number-input"
              value={answers.line1First}
              onChange={(e) => handleInputChange(e.target.value, 'line1First')}
              onKeyDown={handleKeyDown}
              inputRef={firstInputRef}
              autoFocus
              variant="standard"
              style={inputStyle}
            />
            <Typography variant="caption" style={{ color: 'var(--text-secondary)', fontSize: '0.65rem' }}>
              {hints[0]}
            </Typography>
          </div>
          <Typography variant="h5" component="span" style={expressionStyle}>
            {`) + (${number} × `}
          </Typography>
          <div style={inputContainerStyle}>
            <TextField
              className="number-input"
              value={answers.line1Second}
              onChange={(e) => handleInputChange(e.target.value, 'line1Second')}
              onKeyDown={handleKeyDown}
              variant="standard"
              style={inputStyle}
            />
            <Typography variant="caption" style={{ color: 'var(--text-secondary)', fontSize: '0.65rem' }}>
              {hints[1]}
            </Typography>
          </div>
          <Typography variant="h5" component="span" style={expressionStyle}>
            {`)`}
          </Typography>
        </div>

        {/* Line 2: Breaking down multiplication by tens and ones */}
        <div style={lineStyle}>
          <Typography variant="h5" component="span" style={expressionStyle}>
            {`${number} × ${secondNumber} = (${tens1} × `}
          </Typography>
          <div style={inputContainerStyle}>
            <TextField
              className="number-input"
              value={answers.line2First}
              onChange={(e) => handleInputChange(e.target.value, 'line2First')}
              onKeyDown={handleKeyDown}
              variant="standard"
              style={inputStyle}
            />
            <Typography variant="caption" style={{ color: 'var(--text-secondary)', fontSize: '0.65rem' }}>
              {hints[0]}
            </Typography>
          </div>
          <Typography variant="h5" component="span" style={expressionStyle}>
            {` + ${ones1} × `}
          </Typography>
          <div style={inputContainerStyle}>
            <TextField
              className="number-input"
              value={answers.line2Second}
              onChange={(e) => handleInputChange(e.target.value, 'line2Second')}
              onKeyDown={handleKeyDown}
              variant="standard"
              style={inputStyle}
            />
            <Typography variant="caption" style={{ color: 'var(--text-secondary)', fontSize: '0.65rem' }}>
              {hints[0]}
            </Typography>
          </div>
          <Typography variant="h5" component="span" style={expressionStyle}>
            {`) + (${tens1} × `}
          </Typography>
          <div style={inputContainerStyle}>
            <TextField
              className="number-input"
              value={answers.line2Third}
              onChange={(e) => handleInputChange(e.target.value, 'line2Third')}
              onKeyDown={handleKeyDown}
              variant="standard"
              style={inputStyle}
            />
            <Typography variant="caption" style={{ color: 'var(--text-secondary)', fontSize: '0.65rem' }}>
              {hints[1]}
            </Typography>
          </div>
          <Typography variant="h5" component="span" style={expressionStyle}>
            {` + ${ones1} × `}
          </Typography>
          <div style={inputContainerStyle}>
            <TextField
              className="number-input"
              value={answers.line2Fourth}
              onChange={(e) => handleInputChange(e.target.value, 'line2Fourth')}
              onKeyDown={handleKeyDown}
              variant="standard"
              style={inputStyle}
            />
            <Typography variant="caption" style={{ color: 'var(--text-secondary)', fontSize: '0.65rem' }}>
              {hints[1]}
            </Typography>
          </div>
          <Typography variant="h5" component="span" style={expressionStyle}>
            {`)`}
          </Typography>
        </div>

        {/* Line 3: Partial products */}
        <div style={lineStyle}>
          <Typography variant="h5" component="span" style={expressionStyle}>
            {`${number} × ${secondNumber} = (`}
          </Typography>
          <TextField
            className="number-input"
            value={answers.line3First}
            onChange={(e) => handleInputChange(e.target.value, 'line3First')}
            onKeyDown={handleKeyDown}
            variant="standard"
            style={inputStyle}
          />
          <Typography variant="h5" component="span" style={expressionStyle}>
            {` + `}
          </Typography>
          <TextField
            className="number-input"
            value={answers.line3Second}
            onChange={(e) => handleInputChange(e.target.value, 'line3Second')}
            onKeyDown={handleKeyDown}
            variant="standard"
            style={inputStyle}
          />
          <Typography variant="h5" component="span" style={expressionStyle}>
            {`) + (`}
          </Typography>
          <TextField
            className="number-input"
            value={answers.line3Third}
            onChange={(e) => handleInputChange(e.target.value, 'line3Third')}
            onKeyDown={handleKeyDown}
            variant="standard"
            style={inputStyle}
          />
          <Typography variant="h5" component="span" style={expressionStyle}>
            {` + `}
          </Typography>
          <TextField
            className="number-input"
            value={answers.line3Fourth}
            onChange={(e) => handleInputChange(e.target.value, 'line3Fourth')}
            onKeyDown={handleKeyDown}
            variant="standard"
            style={inputStyle}
          />
          <Typography variant="h5" component="span" style={expressionStyle}>
            {`)`}
          </Typography>
        </div>

        {/* Line Combined: Combining partial products */}
        <div style={lineStyle}>
          <Typography variant="h5" component="span" style={expressionStyle}>
            {`${number} × ${secondNumber} = `}
          </Typography>
          <TextField
            className="number-input"
            value={answers.lineCombined1}
            onChange={(e) => handleInputChange(e.target.value, 'lineCombined1')}
            onKeyDown={handleKeyDown}
            variant="standard"
            style={inputStyle}
          />
          <Typography variant="h5" component="span" style={expressionStyle}>
            {` + `}
          </Typography>
          <TextField
            className="number-input"
            value={answers.lineCombined2}
            onChange={(e) => handleInputChange(e.target.value, 'lineCombined2')}
            onKeyDown={handleKeyDown}
            variant="standard"
            style={inputStyle}
          />
        </div>

        {/* Line 4: Final answer */}
        <div style={lineStyle}>
          <Typography variant="h5" component="span" style={expressionStyle}>
            {`${number} × ${secondNumber} = `}
          </Typography>
          <TextField
            className="number-input"
            value={answers.line4}
            onChange={(e) => handleInputChange(e.target.value, 'line4')}
            onKeyDown={handleKeyDown}
            variant="standard"
            style={inputStyle}
          />
        </div>
      </form>
    </div>
  );
};

export default TwoDigitMultiplicationPractice;

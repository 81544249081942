import React, { useState, useEffect, useRef } from 'react';
import { TextField } from '@mui/material';

const FactorPractice = ({ level, onAnswer, currentProgress, inputs: inputsFn }) => {
  const [factors, setFactors] = useState([]);
  const [userInput, setUserInput] = useState('');
  const [number, setNumber] = useState(null);
  const [shouldFocus, setShouldFocus] = useState(false);
  const inputRef = useRef(null);

  // Reset state and set initial number when level or progress changes
  useEffect(() => {
    if (typeof inputsFn !== 'function') return;
    const { number } = inputsFn();
    setNumber(number);
    calculateFactors(number);
    setShouldFocus(true); // Set initial focus
  }, [level, currentProgress, inputsFn]);

  // Focus input after Enter is pressed or initially
  useEffect(() => {
    if (shouldFocus && inputRef.current) {
      inputRef.current.focus();
      setShouldFocus(false);
    }
  }, [shouldFocus]);

  const calculateFactors = (num) => {
    const factorArray = [];
    for (let i = 1; i <= num; i++) {
      if (num % i === 0) {
        factorArray.push(i);
      }
    }
    setFactors(factorArray);
    setUserInput('');
  };

  const handleInputChange = (value) => {
    // Allow numbers, commas, and spaces
    const sanitizedValue = value.replace(/[^0-9,\s]/g, '');
    // Remove duplicate separators
    const cleanedValue = sanitizedValue.replace(/[,\s]+/g, ' ');
    setUserInput(cleanedValue);
  };

  const checkAnswer = () => {
    // Parse input into array of numbers
    const userFactors = userInput
      .split(/[,\s]+/) // Split on commas or spaces
      .filter(x => x.trim()) // Remove empty strings
      .map(x => parseInt(x)) // Convert to numbers
      .filter(x => !isNaN(x)) // Remove invalid numbers
      .sort((a, b) => a - b); // Sort numerically

    // Check if arrays match
    const isCorrect = 
      userFactors.length === factors.length && 
      userFactors.every((factor, index) => factor === factors[index]);

    onAnswer(
      isCorrect ? 'correct' : userFactors.join(','),
      factors.join(',')
    );

    if (isCorrect) {
      const { number: newNumber } = inputsFn();
      setNumber(newNumber);
      calculateFactors(newNumber);
    } else {
      setUserInput('');
    }

    setShouldFocus(true);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      checkAnswer();
    }
  };

  if (!number) return null;

  return (
    <div className="content-box">
      <h2 style={{ color: 'var(--text-primary)', marginBottom: '1rem' }}>
        Find all factors of {number}
      </h2>
      <div style={{ 
        display: 'flex', 
        justifyContent: 'center',
        margin: '2rem 0'
      }}>
        <TextField
          className="number-input"
          value={userInput}
          onChange={(e) => handleInputChange(e.target.value)}
          onKeyDown={handleKeyDown}
          inputRef={inputRef}
          autoFocus
          placeholder=""
          inputProps={{
            style: { textAlign: 'center' }
          }}
          sx={{ minWidth: '300px' }}
        />
      </div>
    </div>
  );
};

export default FactorPractice;

import React, { useState, useEffect, useRef } from 'react';
import { Typography, Box } from '@mui/material';
import '../../../styles/practice.css';

const DigitInput = ({ value, onChange, onKeyDown, inputRef, autoFocus, onArrowKey }) => (
  <input
    type="text"
    value={value}
    onChange={(e) => {
      const val = e.target.value.replace(/\D/g, '');
      if (val.length <= 2) onChange(val);
    }}
    onKeyDown={(e) => {
      if (['ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown'].includes(e.key)) {
        e.preventDefault();
        onArrowKey(e.key);
      } else if (e.key === 'Enter') {
        onKeyDown(e);
      }
    }}
    ref={inputRef}
    autoFocus={autoFocus}
    style={{
      width: '32px',
      height: '32px',
      textAlign: 'center',
      fontSize: '1.2rem',
      fontFamily: 'monospace',
      fontWeight: 'bold',
      color: 'var(--text-primary)',
      backgroundColor: 'var(--bg-primary)',
      border: '1px solid var(--border-color)',
      borderRadius: '4px',
      padding: '0',
      margin: '0 2px'
    }}
  />
);

export default function VerticalOperationsPractice({ level, onAnswer, currentProgress, inputs: inputsFn }) {
  const [problem, setProblem] = useState(null);
  const [answers, setAnswers] = useState({
    carries: [],
    intermediates: [],
    total: []
  });
  
  const inputRefs = useRef({
    carries: [],
    intermediates: [],
    total: []
  });

  useEffect(() => {
    if (typeof inputsFn === 'function') {
      const newProblem = inputsFn();
      setProblem(newProblem);
      resetAnswers(newProblem);
    }
    //eslint-disable-next-line
  }, [level, currentProgress, inputsFn]);

  const getResultLength = (problem) => {
    const num1Str = problem.num1.toString();
    const num2Str = problem.num2.toString();
    const maxLength = Math.max(num1Str.length, num2Str.length);

    switch (problem.operation) {
      case '+':
        return maxLength + 1;
      case '-':
        return num1Str.length; // length of minuend
      case '*':
        return num1Str.length + num2Str.length; // sum of lengths
      default:
        return maxLength;
    }
  };

  const resetAnswers = (newProblem) => {
    const resultLength = getResultLength(newProblem);
    const maxLength = Math.max(newProblem.num1.toString().length, newProblem.num2.toString().length);

    // For multiplication, we need multiple intermediate rows
    const intermediateRows = newProblem.operation === '*' && newProblem.num2.toString().length > 1 
      ? newProblem.num2.toString().length 
      : 1;

    // Add extra carry slot for subtraction's ones place
    const carryLength = maxLength - 1 + (newProblem.operation === '-' ? 1 : 0);

    inputRefs.current = {
      carries: Array(carryLength).fill(null).map(() => React.createRef()),
      intermediates: Array(intermediateRows).fill(null).map(() => 
        Array(resultLength).fill(null).map(() => React.createRef())
      ),
      total: Array(resultLength).fill(null).map(() => React.createRef())
    };

    setAnswers({
      carries: Array(carryLength).fill(''),
      intermediates: Array(intermediateRows).fill(null).map(() => 
        Array(resultLength).fill('')
      ),
      total: Array(resultLength).fill('')
    });
  };

  const handleArrowKey = (key, type, rowIndex, digitIndex) => {
    const refs = inputRefs.current;
    if (!refs) return;

    let nextRef = null;
    const maxRows = problem.operation === '*' && problem.num2.toString().length > 1 
      ? problem.num2.toString().length 
      : 1;
    const resultLength = getResultLength(problem);

    const getCarryBoxForPosition = (fromRight) => {
      // Shift one position right so ones place maps to rightmost carrying box
      const targetCarryIndex = refs.carries.length - 1 - (fromRight - 1);
      if (targetCarryIndex >= 0 && targetCarryIndex < refs.carries.length) {
        return refs.carries[targetCarryIndex];
      } else if (fromRight > 1) {
        // If we're beyond the carrying boxes, go to leftmost carrying box
        return refs.carries[0];
      }
      return null;
    };

    const getInputBoxForCarry = (carryIndex) => {
      // Calculate position from right based on carry position
      const carryFromRight = refs.carries.length - 1 - carryIndex;
      const targetIndex = resultLength - 1 - carryFromRight;
      
      // For multi-digit multiplication, use intermediate row
      if (problem.operation === '*' && problem.num2.toString().length > 1) {
        return refs.intermediates[0]?.[targetIndex];
      }
      // For single-digit operations, use total row
      return refs.total[targetIndex];
    };

    if (type === 'carry') {
      if (key === 'ArrowLeft' && digitIndex > 0) {
        nextRef = refs.carries[digitIndex - 1];
      } else if (key === 'ArrowRight' && digitIndex < refs.carries.length - 1) {
        nextRef = refs.carries[digitIndex + 1];
      } else if (key === 'ArrowDown') {
        nextRef = getInputBoxForCarry(digitIndex);
      }
    } else if (type === 'intermediate') {
      if (key === 'ArrowLeft' && digitIndex > 0) {
        nextRef = refs.intermediates[rowIndex][digitIndex - 1];
      } else if (key === 'ArrowRight' && digitIndex < resultLength - 1) {
        nextRef = refs.intermediates[rowIndex][digitIndex + 1];
      } else if (key === 'ArrowUp') {
        if (rowIndex === 0) {
          // Calculate position from right in the input row
          const inputFromRight = resultLength - 1 - digitIndex;
          nextRef = getCarryBoxForPosition(inputFromRight);
        } else {
          nextRef = refs.intermediates[rowIndex - 1][digitIndex];
        }
      } else if (key === 'ArrowDown') {
        if (rowIndex === maxRows - 1) {
          nextRef = refs.total[digitIndex];
        } else {
          nextRef = refs.intermediates[rowIndex + 1][digitIndex];
        }
      }
    } else if (type === 'total') {
      if (key === 'ArrowLeft' && digitIndex > 0) {
        nextRef = refs.total[digitIndex - 1];
      } else if (key === 'ArrowRight' && digitIndex < resultLength - 1) {
        nextRef = refs.total[digitIndex + 1];
      } else if (key === 'ArrowUp') {
        if (maxRows > 1) {
          nextRef = refs.intermediates[maxRows - 1][digitIndex];
        } else {
          // If no intermediate rows, go directly to carrying boxes
          const inputFromRight = resultLength - 1 - digitIndex;
          nextRef = getCarryBoxForPosition(inputFromRight);
        }
      }
    }

    if (nextRef?.current) {
      nextRef.current.focus();
    }
  };

  const handleDigitChange = (value, type, rowIndex, digitIndex) => {
    setAnswers(prev => {
      const newAnswers = { ...prev };
      
      if (type === 'carry') {
        const newCarries = [...prev.carries];
        newCarries[digitIndex] = value;
        newAnswers.carries = newCarries;
      } else if (type === 'intermediate') {
        const newIntermediates = prev.intermediates.map(row => [...row]);
        newIntermediates[rowIndex][digitIndex] = value;
        newAnswers.intermediates = newIntermediates;
      } else if (type === 'total') {
        const newTotal = [...prev.total];
        newTotal[digitIndex] = value;
        newAnswers.total = newTotal;
      }
      
      return newAnswers;
    });
  };

  const checkAnswer = () => {
    if (!problem) return;

    const { num1, num2 } = problem;
    
    const userTotal = parseInt(answers.total.filter(x => x !== '').join('') || '0');

    if (problem.operation === '*' && num2.toString().length > 1) {
      const num2Digits = num2.toString().split('').reverse();
      const correctIntermediates = num2Digits.map((digit, i) => {
        const product = num1 * parseInt(digit) * Math.pow(10, i);
        return product.toString();
      });

      // Check intermediate products allowing leading and trailing blanks
      const intermediatesCorrect = answers.intermediates.every((row, rowIndex) => {
        // Get the correct intermediate product for this row
        const correctProduct = correctIntermediates[rowIndex];
        
        // Get user's input, filtering out empty strings but preserving position of digits
        const userDigits = row.map(x => x || '');
        
        // Find first non-empty digit
        const firstNonEmptyIndex = userDigits.findIndex(x => x !== '');
        if (firstNonEmptyIndex === -1) return false; // Row is empty
        
        // Get actual digits entered by user (ignoring leading blanks)
        const userProduct = userDigits.slice(firstNonEmptyIndex).join('').replace(/\s+$/, '');
        
        // For rows after the first one (index > 0), we need to account for trailing zeros
        if (rowIndex > 0) {
          // Add back the necessary number of zeros based on row position
          const userValue = parseInt(userProduct || '0') * Math.pow(10, rowIndex);
          return userValue === parseInt(correctProduct);
        }
        
        // For first row, direct comparison is fine
        return parseInt(userProduct || '0') === parseInt(correctProduct);
      });

      if (userTotal === num1 * num2 && intermediatesCorrect) {
        onAnswer('correct', 'correct');
        const newProblem = inputsFn();
        setProblem(newProblem);
        resetAnswers(newProblem);
        if (inputRefs.current.carries[0]?.current) {
          inputRefs.current.carries[0].current.focus();
        } else if (inputRefs.current.intermediates[0]?.[0]?.current) {
          inputRefs.current.intermediates[0][0].current.focus();
        }
      } else {
        onAnswer('incorrect', 'correct');
      }
    } else {
      const expectedAnswer = problem.operation === '+' 
        ? num1 + num2 
        : problem.operation === '-' 
          ? num1 - num2 
          : num1 * num2;

      if (userTotal === expectedAnswer) {
        onAnswer('correct', 'correct');
        const newProblem = inputsFn();
        setProblem(newProblem);
        resetAnswers(newProblem);
        if (inputRefs.current.carries[0]?.current) {
          inputRefs.current.carries[0].current.focus();
        }
      } else {
        onAnswer('incorrect', 'correct');
      }
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      checkAnswer();
    }
  };

  if (!problem) return null;

  const num1Str = problem.num1.toString();
  const num2Str = problem.num2.toString();
  const maxLength = Math.max(num1Str.length, num2Str.length);
  const resultLength = getResultLength(problem);

  return (
    <form onSubmit={handleKeyDown} className="content-box">
      <Box className="vertical-operation">
        <Box className="operation-numbers">
          {/* Carry row */}
          <div style={{ 
            display: 'flex', 
            justifyContent: 'flex-end',
            gap: '0.25rem',
            marginBottom: '2px',
            position: 'relative'
          }}>
            {Array(maxLength - 1).fill(0).map((_, i) => (
              <DigitInput
                key={`carry-${i}`}
                value={answers.carries[i] || ''}
                onChange={(value) => handleDigitChange(value, 'carry', null, i)}
                onKeyDown={handleKeyDown}
                inputRef={inputRefs.current.carries[i]}
                autoFocus={i === 0}
                onArrowKey={(key) => handleArrowKey(key, 'carry', null, i)}
              />
            ))}
            {/* Ones place carry box for subtraction */}
            {problem.operation === '-' ? (
              <DigitInput
                key="subtraction-ones-carry"
                value={answers.carries[maxLength - 1] || ''}
                onChange={(value) => handleDigitChange(value, 'carry', null, maxLength - 1)}
                onKeyDown={handleKeyDown}
                inputRef={inputRefs.current.carries[maxLength - 1]}
                onArrowKey={(key) => handleArrowKey(key, 'carry', null, maxLength - 1)}
              />
            ) : (
              /* Spacer for ones place when not subtraction */
              <div style={{ width: '32px', margin: '0 2px' }}></div>
            )}
          </div>
          
          {/* First number */}
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '0.5rem' }}>
            {num1Str.padStart(resultLength, ' ').split('').map((digit, i) => (
              <Box
                key={`num1-${i}`}
                sx={{
                  width: '32px',
                  height: '32px',
                  margin: '0 2px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  fontSize: '1.2rem',
                  fontFamily: 'monospace',
                  fontWeight: 'bold',
                  color: 'var(--text-primary)'
                }}
              >
                {digit === ' ' ? '' : digit}
              </Box>
            ))}
          </Box>
          
          {/* Operation and second number */}
          <Box className="operation-line-container">
            <Typography className="operation-symbol" sx={{ fontWeight: 'bold' }}>
              {problem.operation === '*' ? '×' : problem.operation}
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              {num2Str.padStart(resultLength, ' ').split('').map((digit, i) => (
                <Box
                  key={`num2-${i}`}
                  sx={{
                    width: '32px',
                    height: '32px',
                    margin: '0 2px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontSize: '1.2rem',
                    fontFamily: 'monospace',
                    fontWeight: 'bold',
                    color: 'var(--text-primary)'
                  }}
                >
                  {digit === ' ' ? '' : digit}
                </Box>
              ))}
            </Box>
          </Box>
          
          <div className="operation-line"></div>

          {/* Intermediate steps for multiplication */}
          {problem.operation === '*' && num2Str.length > 1 ? (
            <>
              {num2Str.split('').reverse().map((_, rowIndex) => (
                <Box
                  key={`intermediate-${rowIndex}`}
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    marginBottom: rowIndex === num2Str.length - 1 ? '1rem' : '0.5rem'
                  }}
                >
                  {Array(resultLength).fill(0).map((_, digitIndex) => (
                    <DigitInput
                      key={`intermediate-${rowIndex}-${digitIndex}`}
                      value={answers.intermediates[rowIndex]?.[digitIndex] || ''}
                      onChange={(value) => handleDigitChange(value, 'intermediate', rowIndex, digitIndex)}
                      onKeyDown={handleKeyDown}
                      inputRef={inputRefs.current.intermediates[rowIndex]?.[digitIndex]}
                      onArrowKey={(key) => handleArrowKey(key, 'intermediate', rowIndex, digitIndex)}
                    />
                  ))}
                </Box>
              ))}
              <div className="operation-line"></div>
            </>
          ) : null}

          {/* Answer row */}
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            {Array(resultLength).fill(0).map((_, i) => (
              <DigitInput
                key={`total-${i}`}
                value={answers.total[i] || ''}
                onChange={(value) => handleDigitChange(value, 'total', null, i)}
                onKeyDown={handleKeyDown}
                inputRef={inputRefs.current.total[i]}
                onArrowKey={(key) => handleArrowKey(key, 'total', null, i)}
              />
            ))}
          </Box>
        </Box>
      </Box>
    </form>
  );
}

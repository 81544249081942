import { roll } from './utils';

export const fractions = {
  "Fractions": {
    levels: [
      { id: "f9n0u1t4", description: "Name Improper Fractions", exerciseType: "NamingFractionsPractice", timeLimit: 90, inputs: () => {
        const denominator = roll(2, 6);
        return {
          numerator: roll(denominator + 1, denominator * 2),
          denominator,
          shape: ['circle', 'rectangle'][roll(0,1)]
        };
      }},
      { id: "f9nllat4", description: "Name Improper Fractions on Number Line", exerciseType: "NamingFractionsPractice", timeLimit: 90, inputs: () => {
        const denominator = roll(2, 6);
        return {
          numerator: roll(denominator + 1, denominator * 2),
          denominator,
          shape: 'numberline'
        };
      }},
    ]
  }
};

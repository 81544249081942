import { useState, useEffect } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { useAuth } from '../contexts/AuthContext';
import { db } from '../firebase';
import gradeContent from '../components/grades/gradeContent';

const useAllGradesProgress = () => {
  const [progressData, setProgressData] = useState({});
  const [loading, setLoading] = useState(true);
  const { currentUser } = useAuth();

  useEffect(() => {
    const loadProgress = async () => {
      if (!currentUser) {
        setLoading(false);
        return;
      }

      try {
        const userRef = doc(db, 'users', currentUser.uid);
        const userDoc = await getDoc(userRef);
        
        if (userDoc.exists()) {
          const userData = userDoc.data();
          // Get completion data from best times
          const levelBestTimes = userData.levelBestTimes || {};
          // For backward compatibility
          const completedLevels = userData.completedLevels || [];
          const allProgress = {};

          // Calculate completion percentage for each grade
          Object.entries(gradeContent).forEach(([grade, content]) => {
            let totalLevels = 0;
            let completedLevelsCount = 0;

            // Count levels across all topics in the grade
            Object.values(content).forEach((topicData) => {
              if (topicData.levels) {
                totalLevels += topicData.levels.length;
                
                // Check each level's completion status
                topicData.levels.forEach(level => {
                  // A level is completed if it has a best time recorded
                  // OR if it's in the old completedLevels array for backward compatibility
                  if (levelBestTimes[level.id] !== undefined || 
                      completedLevels.includes(level.id)) {
                    completedLevelsCount++;
                  }
                });
              }
            });

            // Calculate percentage and ensure it's a number
            const percentage = totalLevels > 0 ? Math.round((completedLevelsCount / totalLevels) * 100) : 0;
            // Store with numeric key to match grade numbers
            allProgress[parseInt(grade)] = percentage;
          });

          setProgressData(allProgress);
        }
      } catch (error) {
        console.error('Error loading grades progress:', error);
      }
      
      setLoading(false);
    };

    loadProgress();
  }, [currentUser]);

  return { progressData, loading };
};

export default useAllGradesProgress;

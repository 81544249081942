import { roll } from './utils';

export const timeMoney = {
  "Time and Money" : {
    levels :[  
      { 
        id: "m2k7p4n9", 
        description: "Time Conversions", 
        exerciseType: "ConversionPractice", 
        timeLimit: 60, 
        inputs: () => {
          const conversions = [
            { fromUnit: 'minute', toUnit: 'second', type: 'time' },
            { fromUnit: 'hour', toUnit: 'minute', type: 'time' },
            { fromUnit: 'day', toUnit: 'hour', type: 'time' },
            { fromUnit: 'week', toUnit: 'day', type: 'time' },
          ];
          const conversion = conversions[roll(0, conversions.length - 1)];
          return {
            values: { [conversion.fromUnit]: 1 },
            fromUnit: conversion.fromUnit,
            toUnit: conversion.toUnit,
            type: conversion.type
          };
        }
      },
      { id: "d9b2c4n7", description: "Telling Time - Hour", exerciseType: "TimePractice", timeLimit: 90, inputs: () => ({
        hour: roll(1, 12),
        minute: 0
      })},
      { id: "z6x8v1q3", description: "Telling Time - Half Hour", exerciseType: "TimePractice", timeLimit: 90, inputs: () => ({
        hour: roll(1, 12),
        minute: 30
      })},
      { id: "z6x8v1af", description: "Telling Time - Hour or Half Hour", exerciseType: "TimePractice", timeLimit: 90, inputs: () => ({
        hour: roll(1, 12),
        minute: roll(0, 1) * 30
      })},
      { id: "t4w3r7h2", description: "Identifying Coins", exerciseType: "ConversionPractice", timeLimit: 60, inputs: () => {
        const coins = ['penny', 'nickel', 'dime'];
        const coin = coins[roll(0, 2)];
        return {
          values: { [coin]: 1 },
          fromUnit: coin,
          toUnit: 'penny',
          type: 'money'
        };
      }}, 
      { id: "t4w3r7z2", description: "Identifying Coins", exerciseType: "ConversionPractice", timeLimit: 45, inputs: () => {
        const coins = ['penny', 'nickel', 'dime', 'quarter', 'half_dollar', 'dollar'];
        const coin = coins[roll(0, coins.length - 1)];
        return {
          values: { [coin]: 1 },
          fromUnit: coin,
          toUnit: 'penny',
          type: 'money'
        };
      }},
      { id: "h5y9f3l7", description: "Convert Between Coins", exerciseType: "ConversionPractice", timeLimit: 45, inputs: () => {
        // Coins ordered from highest to lowest value
        const coins = ['dollar', 'half_dollar', 'quarter', 'dime', 'nickel'];
        
        // Select a larger value coin as the fromCoin
        const fromIndex = roll(0, coins.length - 2); // -2 to ensure we never pick penny as fromCoin
        
        // Select a smaller value coin as the toCoin
        let toIndex;
        let fromCoin;
        let toCoin;
        
        do {
          fromCoin = coins[fromIndex];
          toIndex = roll(fromIndex + 1, coins.length - 1);
          toCoin = coins[toIndex];
        } while (fromCoin === 'quarter' && toCoin === 'dime'); // Skip quarter to dime conversions
        
        // Use small quantities (1-3) for easier mental math
        const quantity = 1;
        
        return {
          values: { [fromCoin]: quantity },
          fromUnit: fromCoin,
          toUnit: toCoin,
          type: 'money'
        };
      }},
      { id: "h5y9as7", description: "Convert Coins", exerciseType: "ConversionPractice", timeLimit: 60, inputs: () => {
        // Coins ordered from highest to lowest value
        const coins = ['dollar', 'half_dollar', 'quarter', 'dime', 'nickel'];
        
        // Select a larger value coin as the fromCoin
        const fromIndex = roll(0, coins.length - 2); // -2 to ensure we never pick penny as fromCoin
        
        // Select a smaller value coin as the toCoin
        let toIndex;
        let fromCoin;
        let toCoin;
        
        do {
          fromCoin = coins[fromIndex];
          toIndex = roll(fromIndex + 1, coins.length - 1);
          toCoin = coins[toIndex];
        } while (fromCoin === 'quarter' && toCoin === 'dime'); // Skip quarter to dime conversions
        
        // Use small quantities (2-4) for easier mental math
        const quantity = roll(2,4);
        
        return {
          values: { [fromCoin]: quantity },
          fromUnit: fromCoin,
          toUnit: toCoin,
          type: 'money'
        };
      }}
   ]
  }
};

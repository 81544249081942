import { roll } from './utils';

export const multiplication = {
  "Multidigit Multiplication": {
    levels: [
      { 
        id: "3dasd8af", 
        description: "Multiply by Tens, Hundreds, Thousands", 
        exerciseType: "OperationsPractice", 
        timeLimit: 45, 
        inputs: () => ({ 
          num1: 10**roll(1,3), 
          num2: roll(3,9),
          operation: '*' 
        })
      },
      { 
        id: "m4p2n8af", 
        description: "Multiply by Tens by Ones", 
        exerciseType: "OperationsPractice", 
        timeLimit: 45, 
        inputs: () => ({ 
          num1: roll(3, 9)*10, 
          num2: roll(3,9), 
          operation: '*' 
        })
      },
      { 
        id: "ee4p1n1",
        description: "Break Down Two Digits Times Ones",
        exerciseType: "EquivalentExpressionPractice",
        timeLimit: 180,
        inputs: () => {
          const tens = roll(2, 9);
          const ones = roll(2, 9);
          const number = tens * 10 + ones;
          const secondNumber = roll(2, 9);
          return {
            number,
            secondNumber,
            operation: '×',
            split: {
              firstPart: tens * 10,  // tens part (e.g., 40 from 45)
              secondPart: ones       // ones part (e.g., 5 from 45)
            },
            hints: ['tens', 'ones']
          };
        }
      },
      { 
        id: "j6asc8f4", 
        description: "Two Digits Times Ones",
        exerciseType: "VerticalOperationsPractice", 
        timeLimit: 240,
        inputs: () => {
          const num1 = roll(3,9)*10+roll(3,9); 
          return {
            num1,
            num2: roll(3,9),
            operation: '*'
          };
        }
      },
      { 
        id: "m4prewqf", 
        description: "Multiply by Hundreds by Ones", 
        exerciseType: "OperationsPractice", 
        timeLimit: 45, 
        inputs: () => ({ 
          num1: roll(3, 9)*100, 
          num2: roll(3,9), 
          operation: '*' 
        })
      },
      {
        id: "ee4p3n1",
        description: "Break Down Three Digits Times Ones ",
        exerciseType: "EquivalentExpressionPractice",
        timeLimit: 360,
        inputs: () => {
          const hundreds = roll(3, 9);
          const tens = roll(3, 9);
          const ones = roll(3, 9);
          const number = hundreds * 100 + tens * 10 + ones;
          const secondNumber = roll(3, 9);
          return {
            number,
            secondNumber,
            operation: '×',
            split: {
              firstPart: hundreds * 100,  // hundreds part (e.g., 300 from 345)
              secondPart: tens * 10,      // tens part (e.g., 40 from 345)
              thirdPart: ones            // ones part (e.g., 5 from 345)
            },
            hints: ['hundreds', 'tens', 'ones'],
            isThreeDigit: true
          };
        }
      },
      { 
        id: "jacdc8f4", 
        description: "Three Digits Times One Digit", 
        exerciseType: "VerticalOperationsPractice", 
        timeLimit: 300,
        inputs: () => {
          const num1 = roll(102, 998);
          return {
            num1,
            num2: roll(2, 9),
            operation: '*'
          };
        }
      },
      { 
        id: "mrr2n8af", 
        description: "Multiply by Tens by Ten", 
        exerciseType: "OperationsPractice", 
        timeLimit: 45, 
        inputs: () => ({ 
          num1: roll(3, 9)*10, 
          num2: 10, 
          operation: '*' 
        })
      },
      { 
        id: "m4zdn8af", 
        description: "Multiply by Tens by Tens", 
        exerciseType: "OperationsPractice", 
        timeLimit: 45, 
        inputs: () => ({ 
          num1: roll(3, 9)*10, 
          num2: roll(3,9)*10, 
          operation: '*' 
        })
      },
      { 
        id: "egap1n1",
        description: "Break Down Two Digits Times Tens",
        exerciseType: "EquivalentExpressionPractice",
        timeLimit: 180,
        inputs: () => {
          const tens = roll(2, 9);
          const ones = roll(2, 9);
          const number = tens * 10 + ones;
          const secondNumber = roll(2, 9) * 10;
          return {
            number,
            secondNumber,
            operation: '×',
            split: {
              firstPart: tens * 10,  // tens part (e.g., 40 from 45)
              secondPart: ones       // ones part (e.g., 5 from 45)
            },
            hints: ['tens', 'ones']
          };
        }
      },
      {
        id: "ee4p2d2d",
        description: "Break Down Two Digits Times Two Digits",
        exerciseType: "TwoDigitMultiplicationPractice",
        timeLimit: 300,
        inputs: () => {
          const tens1 = roll(2, 9);
          const ones1 = roll(2, 9);
          const tens2 = roll(2, 9);
          const ones2 = roll(2, 9);
          const number = tens1 * 10 + ones1;
          const secondNumber = tens2 * 10 + ones2;

          // Calculate partial products
          const tensTimesTens = (tens1 * 10) * (tens2 * 10);    // 90 × 60
          const tensTimesOnes = (tens1 * 10) * ones2;           // 90 × 2
          const onesTimesTens = ones1 * (tens2 * 10);           // 5 × 60
          const onesTimesOnes = ones1 * ones2;                  // 5 × 2

          return {
            number,
            secondNumber,
            operation: '×',
            split: {
              firstPart: tens2 * 10,    // tens part of second number (e.g., 60 from 62)
              secondPart: ones2,        // ones part of second number (e.g., 2 from 62)
              firstNumberParts: {
                tens: tens1 * 10,       // tens part of first number (e.g., 90 from 95)
                ones: ones1            // ones part of first number (e.g., 5 from 95)
              }
            },
            hints: ['tens', 'ones'],
            partialProducts: {
              tensTimesTens,
              tensTimesOnes,
              onesTimesTens,
              onesTimesOnes
            },
            isTwoDigitByTwoDigit: true,
            format: 'expanded'  // indicates new expanded format
          };
        }
      },
      { 
        id: "j6fgc8f4", 
        description: "Two Digits Times Two Digits",
        exerciseType: "VerticalOperationsPractice", 
        timeLimit: 300,
        inputs: () => {
          const num1 = roll(3,9)*10+roll(3,9); 
          const num2 = roll(3,9)*10+roll(3,9); 
          return {
            num1,
            num2,
            operation: '*'
          };
        }
      },
    ]
  }
};

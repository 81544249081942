export const roll = (min, max) => Math.floor(Math.random() * (max - min + 1)) + min;

export const randomizeFactors = (factor1, factor2) => {
  return roll(0, 1) === 0 ? { num1: factor1, num2: factor2 } : { num1: factor2, num2: factor1 };
};

export const getRandomShape = () => {
    const shapes = ['circle', 'rectangle', 'numberline'];
    return shapes[roll(0, shapes.length - 1)];
};

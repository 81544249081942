import React, { useState, useEffect, useRef } from 'react';
import { TextField, Typography } from '@mui/material';

const DivisionWithRemainderPractice = ({ level, onAnswer, currentProgress, inputs: inputsFn }) => {
  const [quotient, setQuotient] = useState('');
  const [remainder, setRemainder] = useState('');
  const [problem, setProblem] = useState(null);
  const [shouldFocus, setShouldFocus] = useState(false);
  const quotientRef = useRef(null);
  const remainderRef = useRef(null);

  // Reset state and set initial problem when level or progress changes
  useEffect(() => {
    if (typeof inputsFn !== 'function') return;
    const newProblem = inputsFn();
    setProblem(newProblem);
    setQuotient('');
    setRemainder('');
    setShouldFocus(true);
  }, [level, currentProgress, inputsFn]);

  // Focus quotient input after reset
  useEffect(() => {
    if (shouldFocus && quotientRef.current) {
      quotientRef.current.focus();
      setShouldFocus(false);
    }
  }, [shouldFocus]);

  const handleQuotientChange = (e) => {
    const value = e.target.value.replace(/[^0-9]/g, '');
    setQuotient(value);
  };

  const handleRemainderChange = (e) => {
    const value = e.target.value.replace(/[^0-9]/g, '');
    setRemainder(value);
  };

  const checkAnswer = () => {
    if (!problem) return;

    const expectedQuotient = Math.floor(problem.num1 / problem.num2);
    const expectedRemainder = problem.num1 % problem.num2;
    const userQuotient = quotient ? parseInt(quotient) : 0;
    const userRemainder = remainder ? parseInt(remainder) : 0;
    const isCorrect = userQuotient === expectedQuotient && userRemainder === expectedRemainder;

    onAnswer(
      isCorrect ? 'correct' : `${userQuotient} R${userRemainder}`,
      `${expectedQuotient} R${expectedRemainder}`
    );

    // Generate new problem for both correct and incorrect answers
    const newProblem = inputsFn();
    setProblem(newProblem);
    setQuotient('');
    setRemainder('');
    setShouldFocus(true);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      checkAnswer();
    }
  };

  if (!problem) return null;

  return (
    <div className="content-box">
      <Typography variant="h4" className="practice-title">
        {problem.num1} ÷ {problem.num2}
      </Typography>
      <div style={{ 
        display: 'flex', 
        justifyContent: 'center',
        alignItems: 'center',
        gap: '1rem',
        margin: '2rem 0'
      }}>
        <TextField
          className="number-input"
          value={quotient}
          onChange={handleQuotientChange}
          onKeyDown={handleKeyDown}
          inputRef={quotientRef}
          autoFocus
          placeholder=""
          inputProps={{
            style: { textAlign: 'center' }
          }}
        />
        <Typography variant="h5" style={{ color: 'var(--text-primary)' }}>R</Typography>
        <TextField
          className="number-input"
          value={remainder}
          onChange={handleRemainderChange}
          onKeyDown={handleKeyDown}
          inputRef={remainderRef}
          placeholder=""
          inputProps={{
            style: { textAlign: 'center' }
          }}
        />
      </div>
    </div>
  );
};

export default DivisionWithRemainderPractice;

import React, { useState, useEffect, useRef } from 'react';
import { Typography, TextField } from '@mui/material';

const EquivalentExpressionPractice = ({ inputs: inputsFn, onAnswer }) => {
  const [answers, setAnswers] = useState({
    step1First: '',
    step1Second: '',
    step1Third: '',
    step1Fourth: '',
    step1Fifth: '',
    step1Sixth: '',
    step2First: '',
    step2Second: '',
    step2Third: '',
    step3: ''
  });
  const [problem, setProblem] = useState(null);
  const firstInputRef = useRef(null);

  useEffect(() => {
    if (!problem) {
      const newInputs = typeof inputsFn === 'function' ? inputsFn() : inputsFn;
      setProblem(newInputs);
    }
    // eslint-disable-next-line
  }, []);

  const handleInputChange = (value, field) => {
    if (/^-?\d*$/.test(value)) {
      setAnswers(prev => ({ ...prev, [field]: value }));
    }
  };

  const checkAnswers = () => {
    if (!problem) return;

    const { number, operation, secondNumber, split, isThreeDigit } = problem;
    const { firstPart, secondPart, thirdPart } = split;
    
    let firstResult, secondResult, thirdResult, total;
    let isCorrect = false;
    
    if (operation === '+' || operation === '-') {
      if (isThreeDigit) {
        // Check if all three-digit inputs are filled
        if (!answers.step1First || !answers.step1Second || 
            !answers.step1Third || !answers.step1Fourth ||
            !answers.step1Fifth || !answers.step1Sixth ||
            !answers.step2First || !answers.step2Second || 
            !answers.step2Third || !answers.step3) return;

        const num1hundreds = Math.floor(number / 100) * 100;
        const num1tens = Math.floor((number % 100) / 10) * 10;
        const num1ones = number % 10;
        const num2hundreds = Math.floor(secondNumber / 100) * 100;
        const num2tens = Math.floor((secondNumber % 100) / 10) * 10;
        const num2ones = secondNumber % 10;

        if (operation === '+') {
          firstResult = num1hundreds + num2hundreds;  // sum of hundreds
          secondResult = num1tens + num2tens;  // sum of tens
          thirdResult = num1ones + num2ones; // sum of ones
          total = number + secondNumber;

          isCorrect = 
            answers.step1First === num1hundreds.toString() &&
            answers.step1Second === num2hundreds.toString() &&
            answers.step1Third === num1tens.toString() &&
            answers.step1Fourth === num2tens.toString() &&
            answers.step1Fifth === num1ones.toString() &&
            answers.step1Sixth === num2ones.toString() &&
            answers.step2First === firstResult.toString() &&
            answers.step2Second === secondResult.toString() &&
            answers.step2Third === thirdResult.toString() &&
            answers.step3 === total.toString();
        } else {
          // For subtraction, check if regrouping is needed
          const needsOnesRegrouping = num1ones < num2ones;
          const needsTensRegrouping = needsOnesRegrouping ? 
            (num1tens - 10) < num2tens : num1tens < num2tens;

          const regroupedHundreds = needsTensRegrouping ? num1hundreds - 100 : num1hundreds;
          const regroupedTens = needsTensRegrouping ? 
            (needsOnesRegrouping ? num1tens + 90 : num1tens + 100) : 
            (needsOnesRegrouping ? num1tens - 10 : num1tens);
          const regroupedOnes = needsOnesRegrouping ? num1ones + 10 : num1ones;

          firstResult = regroupedHundreds - num2hundreds;  // difference of hundreds
          secondResult = regroupedTens - num2tens;  // difference of tens
          thirdResult = regroupedOnes - num2ones; // difference of ones
          total = number - secondNumber;

          isCorrect = 
            answers.step1First === regroupedHundreds.toString() &&
            answers.step1Second === num2hundreds.toString() &&
            answers.step1Third === regroupedTens.toString() &&
            answers.step1Fourth === num2tens.toString() &&
            answers.step1Fifth === regroupedOnes.toString() &&
            answers.step1Sixth === num2ones.toString() &&
            answers.step2First === firstResult.toString() &&
            answers.step2Second === secondResult.toString() &&
            answers.step2Third === thirdResult.toString() &&
            answers.step3 === total.toString();
        }
      } else {
        // Two-digit case
        if (!answers.step1First || !answers.step1Second || 
            !answers.step1Third || !answers.step1Fourth ||
            !answers.step2First || !answers.step2Second || 
            !answers.step3) return;

        const num1tens = Math.floor(number / 10) * 10;
        const num1ones = number % 10;
        const num2tens = Math.floor(secondNumber / 10) * 10;
        const num2ones = secondNumber % 10;

        if (operation === '+') {
          firstResult = num1tens + num2tens;  // sum of tens
          secondResult = num1ones + num2ones; // sum of ones
          total = number + secondNumber;

          isCorrect = 
            answers.step1First === num1tens.toString() &&
            answers.step1Second === num2tens.toString() &&
            answers.step1Third === num1ones.toString() &&
            answers.step1Fourth === num2ones.toString() &&
            answers.step2First === firstResult.toString() &&
            answers.step2Second === secondResult.toString() &&
            answers.step3 === total.toString();
        } else {
          // For subtraction, check if regrouping is needed
          const needsRegrouping = num1ones < num2ones;
          const regroupedTens = needsRegrouping ? num1tens - 10 : num1tens;
          const regroupedOnes = needsRegrouping ? num1ones + 10 : num1ones;

          firstResult = regroupedTens - num2tens;  // difference of tens
          secondResult = regroupedOnes - num2ones; // difference of ones
          total = number - secondNumber;

          isCorrect = 
            answers.step1First === regroupedTens.toString() &&
            answers.step1Second === num2tens.toString() &&
            answers.step1Third === regroupedOnes.toString() &&
            answers.step1Fourth === num2ones.toString() &&
            answers.step2First === firstResult.toString() &&
            answers.step2Second === secondResult.toString() &&
            answers.step3 === total.toString();
        }
      }
    } else {
      // Multiplication case
      if (isThreeDigit) {
        if (!answers.step1First || !answers.step1Second || !answers.step1Third ||
            !answers.step2First || !answers.step2Second || !answers.step2Third ||
            !answers.step3) return;

        firstResult = firstPart * secondNumber;
        secondResult = secondPart * secondNumber;
        thirdResult = thirdPart * secondNumber;
        total = number * secondNumber;

        isCorrect = 
          answers.step1First === firstPart.toString() &&
          answers.step1Second === secondPart.toString() &&
          answers.step1Third === thirdPart.toString() &&
          answers.step2First === firstResult.toString() &&
          answers.step2Second === secondResult.toString() &&
          answers.step2Third === thirdResult.toString() &&
          answers.step3 === total.toString();
      } else {
        if (!answers.step1First || !answers.step1Second || 
            !answers.step2First || !answers.step2Second || 
            !answers.step3) return;

        firstResult = firstPart * secondNumber;
        secondResult = secondPart * secondNumber;
        total = number * secondNumber;

        isCorrect = 
          answers.step1First === firstPart.toString() &&
          answers.step1Second === secondPart.toString() &&
          answers.step2First === firstResult.toString() &&
          answers.step2Second === secondResult.toString() &&
          answers.step3 === total.toString();
      }
    }

    if (isCorrect) {
      const newInputs = typeof inputsFn === 'function' ? inputsFn() : inputsFn;
      setProblem(newInputs);
      setAnswers({
        step1First: '',
        step1Second: '',
        step1Third: '',
        step1Fourth: '',
        step1Fifth: '',
        step1Sixth: '',
        step2First: '',
        step2Second: '',
        step2Third: '',
        step3: ''
      });
      onAnswer('correct');
      firstInputRef.current?.focus();
    } else {
      if (operation === '+' || operation === '-') {
        if (isThreeDigit) {
          const num1hundreds = Math.floor(number / 100) * 100;
          const num1tens = Math.floor((number % 100) / 10) * 10;
          const num1ones = number % 10;
          const num2hundreds = Math.floor(secondNumber / 100) * 100;
          const num2tens = Math.floor((secondNumber % 100) / 10) * 10;
          const num2ones = secondNumber % 10;

          if (operation === '-') {
            const needsOnesRegrouping = num1ones < num2ones;
            const needsTensRegrouping = needsOnesRegrouping ? 
              (num1tens - 10) < num2tens : num1tens < num2tens;

            const regroupedHundreds = needsTensRegrouping ? num1hundreds - 100 : num1hundreds;
            const regroupedTens = needsTensRegrouping ? 
              (needsOnesRegrouping ? num1tens + 90 : num1tens + 100) : 
              (needsOnesRegrouping ? num1tens - 10 : num1tens);
            const regroupedOnes = needsOnesRegrouping ? num1ones + 10 : num1ones;

            firstResult = regroupedHundreds - num2hundreds;
            secondResult = regroupedTens - num2tens;
            thirdResult = regroupedOnes - num2ones;
            onAnswer(
              `${answers.step1First},${answers.step1Second},${answers.step1Third},${answers.step1Fourth},${answers.step1Fifth},${answers.step1Sixth},${answers.step2First},${answers.step2Second},${answers.step2Third},${answers.step3}`,
              `${regroupedHundreds},${num2hundreds},${regroupedTens},${num2tens},${regroupedOnes},${num2ones},${firstResult},${secondResult},${thirdResult},${total}`
            );
          } else {
            onAnswer(
              `${answers.step1First},${answers.step1Second},${answers.step1Third},${answers.step1Fourth},${answers.step1Fifth},${answers.step1Sixth},${answers.step2First},${answers.step2Second},${answers.step2Third},${answers.step3}`,
              `${num1hundreds},${num2hundreds},${num1tens},${num2tens},${num1ones},${num2ones},${firstResult},${secondResult},${thirdResult},${total}`
            );
          }
        } else {
          const num1tens = Math.floor(number / 10) * 10;
          const num1ones = number % 10;
          const num2tens = Math.floor(secondNumber / 10) * 10;
          const num2ones = secondNumber % 10;

          if (operation === '-') {
            const needsRegrouping = num1ones < num2ones;
            const regroupedTens = needsRegrouping ? num1tens - 10 : num1tens;
            const regroupedOnes = needsRegrouping ? num1ones + 10 : num1ones;
            firstResult = regroupedTens - num2tens;
            secondResult = regroupedOnes - num2ones;
            onAnswer(
              `${answers.step1First},${answers.step1Second},${answers.step1Third},${answers.step1Fourth},${answers.step2First},${answers.step2Second},${answers.step3}`,
              `${regroupedTens},${num2tens},${regroupedOnes},${num2ones},${firstResult},${secondResult},${total}`
            );
          } else {
            onAnswer(
              `${answers.step1First},${answers.step1Second},${answers.step1Third},${answers.step1Fourth},${answers.step2First},${answers.step2Second},${answers.step3}`,
              `${num1tens},${num2tens},${num1ones},${num2ones},${firstResult},${secondResult},${total}`
            );
          }
        }
      } else {
        if (isThreeDigit) {
          onAnswer(
            `${answers.step1First},${answers.step1Second},${answers.step1Third},${answers.step2First},${answers.step2Second},${answers.step2Third},${answers.step3}`,
            `${firstPart},${secondPart},${thirdPart},${firstResult},${secondResult},${thirdResult},${total}`
          );
        } else {
          onAnswer(
            `${answers.step1First},${answers.step1Second},${answers.step2First},${answers.step2Second},${answers.step3}`,
            `${firstPart},${secondPart},${firstResult},${secondResult},${total}`
          );
        }
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    checkAnswers();
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      checkAnswers();
    }
  };

  if (!problem) return null;

  const { number, operation, secondNumber, hints = [], isThreeDigit } = problem;

  // Adjust font size and spacing based on whether it's three-digit or not
  const expressionFontSize = isThreeDigit ? '1.5rem' : '2rem';
  const gapSize = isThreeDigit ? '8px' : '16px';  // Increased gaps for better readability
  const inputWidth = isThreeDigit ? '50px' : '60px';
  const inputContainerStyle = {
    display: 'inline-flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: inputWidth,
    margin: '0 2px',  // Increased margin between input containers
    whiteSpace: 'nowrap'
  };

  const expressionStyle = {
    color: 'var(--text-primary)',
    fontSize: expressionFontSize,
    whiteSpace: 'nowrap',
    display: 'inline-flex',
    alignItems: 'center',
    padding: '0 4px'  // Added padding around operation symbols
  };

  return (
    <div className="content-box">
      <Typography className="practice-title">
        Break down the {
          operation === '×' ? 'multiplication' : 
          operation === '+' ? 'addition' :
          'subtraction'
        } problem
      </Typography>
      <form onSubmit={handleSubmit} className="expression-form">
        <div style={{ 
          display: 'flex', 
          flexDirection: 'column', 
          gap: '32px',  // Increased vertical gap between lines
          alignItems: 'center',
          margin: '32px 0',  // Increased margin
          whiteSpace: 'nowrap'
        }}>
          {/* Line 1: Break down the problem */}
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <div style={{ display: 'flex', alignItems: 'center', gap: gapSize, whiteSpace: 'nowrap' }}>
              <Typography variant="h5" component="span" style={expressionStyle}>
                {`${number} ${operation} ${secondNumber} = `}
              </Typography>
              {operation === '+' || operation === '-' ? (
                <>
                  {isThreeDigit ? (
                    <>
                      {operation === '-' && <Typography variant="h5" component="span" style={expressionStyle}>{`(`}</Typography>}
                      <div style={inputContainerStyle}>
                        <TextField
                          className="number-input"
                          value={answers.step1First}
                          onChange={(e) => handleInputChange(e.target.value, 'step1First')}
                          onKeyDown={handleKeyDown}
                          inputRef={firstInputRef}
                          autoFocus
                          variant="standard"
                          style={{ width: inputWidth }}
                        />
                        <Typography variant="caption" style={{ color: 'var(--text-secondary)', marginTop: '4px', fontSize: '0.65rem', whiteSpace: 'nowrap' }}>
                          {hints[0]} {/* First tens */}
                        </Typography>
                      </div>
                      <Typography variant="h5" component="span" style={expressionStyle}>
                        {` ${operation} `}
                      </Typography>
                      <div style={inputContainerStyle}>
                        <TextField
                          className="number-input"
                          value={answers.step1Second}
                          onChange={(e) => handleInputChange(e.target.value, 'step1Second')}
                          onKeyDown={handleKeyDown}
                          variant="standard"
                          style={{ width: inputWidth }}
                        />
                        <Typography variant="caption" style={{ color: 'var(--text-secondary)', marginTop: '4px', fontSize: '0.65rem', whiteSpace: 'nowrap' }}>
                          {hints[0]} {/* Second tens */}
                        </Typography>
                      </div>
                      {operation === '-' ? (
                        <Typography variant="h5" component="span" style={expressionStyle}>
                          {`) + (`}
                        </Typography>
                      ) : (
                        <Typography variant="h5" component="span" style={expressionStyle}>
                          {` + `}
                        </Typography>
                      )}
                      <div style={inputContainerStyle}>
                        <TextField
                          className="number-input"
                          value={answers.step1Third}
                          onChange={(e) => handleInputChange(e.target.value, 'step1Third')}
                          onKeyDown={handleKeyDown}
                          variant="standard"
                          style={{ width: inputWidth }}
                        />
                        <Typography variant="caption" style={{ color: 'var(--text-secondary)', marginTop: '4px', fontSize: '0.65rem', whiteSpace: 'nowrap' }}>
                          {!isThreeDigit && operation === '+' ? hints[1] : hints[1]} {/* First ones */}
                        </Typography>
                      </div>
                      <Typography variant="h5" component="span" style={expressionStyle}>
                        {` ${operation} `}
                      </Typography>
                      <div style={inputContainerStyle}>
                        <TextField
                          className="number-input"
                          value={answers.step1Fourth}
                          onChange={(e) => handleInputChange(e.target.value, 'step1Fourth')}
                          onKeyDown={handleKeyDown}
                          variant="standard"
                          style={{ width: inputWidth }}
                        />
                        <Typography variant="caption" style={{ color: 'var(--text-secondary)', marginTop: '4px', fontSize: '0.65rem', whiteSpace: 'nowrap' }}>
                          {!isThreeDigit && operation === '+' ? hints[1] : hints[1]} {/* Second ones */}
                        </Typography>
                      </div>
                      {operation === '-' ? (
                        <Typography variant="h5" component="span" style={expressionStyle}>
                          {`) + (`}
                        </Typography>
                      ) : (
                        <Typography variant="h5" component="span" style={expressionStyle}>
                          {` + `}
                        </Typography>
                      )}
                      <div style={inputContainerStyle}>
                        <TextField
                          className="number-input"
                          value={answers.step1Fifth}
                          onChange={(e) => handleInputChange(e.target.value, 'step1Fifth')}
                          onKeyDown={handleKeyDown}
                          variant="standard"
                          style={{ width: inputWidth }}
                        />
                        <Typography variant="caption" style={{ color: 'var(--text-secondary)', marginTop: '4px', fontSize: '0.65rem', whiteSpace: 'nowrap' }}>
                          {hints[2]}
                        </Typography>
                      </div>
                      <Typography variant="h5" component="span" style={expressionStyle}>
                        {` ${operation} `}
                      </Typography>
                      <div style={inputContainerStyle}>
                        <TextField
                          className="number-input"
                          value={answers.step1Sixth}
                          onChange={(e) => handleInputChange(e.target.value, 'step1Sixth')}
                          onKeyDown={handleKeyDown}
                          variant="standard"
                          style={{ width: inputWidth }}
                        />
                        <Typography variant="caption" style={{ color: 'var(--text-secondary)', marginTop: '4px', fontSize: '0.65rem', whiteSpace: 'nowrap' }}>
                          {hints[2]}
                        </Typography>
                      </div>
                      {operation === '-' && <Typography variant="h5" component="span" style={expressionStyle}>{`)`}</Typography>}
                    </>
                  ) : (
                    <>
                      {operation === '-' && <Typography variant="h5" component="span" style={expressionStyle}>{`(`}</Typography>}
                      <div style={inputContainerStyle}>
                        <TextField
                          className="number-input"
                          value={answers.step1First}
                          onChange={(e) => handleInputChange(e.target.value, 'step1First')}
                          onKeyDown={handleKeyDown}
                          inputRef={firstInputRef}
                          autoFocus
                          variant="standard"
                          style={{ width: inputWidth }}
                        />
                        <Typography variant="caption" style={{ color: 'var(--text-secondary)', marginTop: '4px', fontSize: '0.65rem', whiteSpace: 'nowrap' }}>
                          {hints[0]}
                        </Typography>
                      </div>
                      <Typography variant="h5" component="span" style={expressionStyle}>
                        {` ${operation} `}
                      </Typography>
                      <div style={inputContainerStyle}>
                        <TextField
                          className="number-input"
                          value={answers.step1Second}
                          onChange={(e) => handleInputChange(e.target.value, 'step1Second')}
                          onKeyDown={handleKeyDown}
                          variant="standard"
                          style={{ width: inputWidth }}
                        />
                        <Typography variant="caption" style={{ color: 'var(--text-secondary)', marginTop: '4px', fontSize: '0.65rem', whiteSpace: 'nowrap' }}>
                          {hints[0]}
                        </Typography>
                      </div>
                      {operation === '-' ? (
                        <Typography variant="h5" component="span" style={expressionStyle}>
                          {`) + (`}
                        </Typography>
                      ) : (
                        <Typography variant="h5" component="span" style={expressionStyle}>
                          {` + `}
                        </Typography>
                      )}
                      <div style={inputContainerStyle}>
                        <TextField
                          className="number-input"
                          value={answers.step1Third}
                          onChange={(e) => handleInputChange(e.target.value, 'step1Third')}
                          onKeyDown={handleKeyDown}
                          variant="standard"
                          style={{ width: inputWidth }}
                        />
                        <Typography variant="caption" style={{ color: 'var(--text-secondary)', marginTop: '4px', fontSize: '0.65rem', whiteSpace: 'nowrap' }}>
                          {hints[1]}
                        </Typography>
                      </div>
                      <Typography variant="h5" component="span" style={expressionStyle}>
                        {` ${operation} `}
                      </Typography>
                      <div style={inputContainerStyle}>
                        <TextField
                          className="number-input"
                          value={answers.step1Fourth}
                          onChange={(e) => handleInputChange(e.target.value, 'step1Fourth')}
                          onKeyDown={handleKeyDown}
                          variant="standard"
                          style={{ width: inputWidth }}
                        />
                        <Typography variant="caption" style={{ color: 'var(--text-secondary)', marginTop: '4px', fontSize: '0.65rem', whiteSpace: 'nowrap' }}>
                          {hints[1]}
                        </Typography>
                      </div>
                      {operation === '-' && <Typography variant="h5" component="span" style={expressionStyle}>{`)`}</Typography>}
                    </>
                  )}
                </>
              ) : (
                <>
                  <div style={inputContainerStyle}>
                    <TextField
                      className="number-input"
                      value={answers.step1First}
                      onChange={(e) => handleInputChange(e.target.value, 'step1First')}
                      onKeyDown={handleKeyDown}
                      inputRef={firstInputRef}
                      autoFocus
                      variant="standard"
                      style={{ width: inputWidth }}
                    />
                    <Typography variant="caption" style={{ color: 'var(--text-secondary)', marginTop: '4px', fontSize: '0.65rem', whiteSpace: 'nowrap' }}>
                      {hints[0]}
                    </Typography>
                  </div>
                  <Typography variant="h5" component="span" style={expressionStyle}>
                    {` ${operation} ${secondNumber} + `}
                  </Typography>
                  <div style={inputContainerStyle}>
                    <TextField
                      className="number-input"
                      value={answers.step1Second}
                      onChange={(e) => handleInputChange(e.target.value, 'step1Second')}
                      onKeyDown={handleKeyDown}
                      variant="standard"
                      style={{ width: inputWidth }}
                    />
                    <Typography variant="caption" style={{ color: 'var(--text-secondary)', marginTop: '4px', fontSize: '0.65rem', whiteSpace: 'nowrap' }}>
                      {hints[1]}
                    </Typography>
                  </div>
                  {isThreeDigit && (
                    <>
                      <Typography variant="h5" component="span" style={expressionStyle}>
                        {` ${operation} ${secondNumber} + `}
                      </Typography>
                      <div style={inputContainerStyle}>
                        <TextField
                          className="number-input"
                          value={answers.step1Third}
                          onChange={(e) => handleInputChange(e.target.value, 'step1Third')}
                          onKeyDown={handleKeyDown}
                          variant="standard"
                          style={{ width: inputWidth }}
                        />
                        <Typography variant="caption" style={{ color: 'var(--text-secondary)', marginTop: '4px', fontSize: '0.65rem', whiteSpace: 'nowrap' }}>
                          {hints[2]}
                        </Typography>
                      </div>
                    </>
                  )}
                  <Typography variant="h5" component="span" style={expressionStyle}>
                    {` ${operation} ${secondNumber}`}
                  </Typography>
                </>
              )}
            </div>
          </div>

          {/* Line 2: Show operation results */}
          <div style={{ display: 'flex', alignItems: 'center', gap: gapSize, whiteSpace: 'nowrap' }}>
            <Typography variant="h5" component="span" style={expressionStyle}>
              {`${number} ${operation} ${secondNumber} = `}
            </Typography>
            <TextField
              className="number-input"
              value={answers.step2First}
              onChange={(e) => handleInputChange(e.target.value, 'step2First')}
              onKeyDown={handleKeyDown}
              variant="standard"
              style={{ width: inputWidth }}
            />
            <Typography variant="h5" component="span" style={expressionStyle}>
              {` + `}
            </Typography>
            <TextField
              className="number-input"
              value={answers.step2Second}
              onChange={(e) => handleInputChange(e.target.value, 'step2Second')}
              onKeyDown={handleKeyDown}
              variant="standard"
              style={{ width: inputWidth }}
            />
            {isThreeDigit && (
              <>
                <Typography variant="h5" component="span" style={expressionStyle}>
                  {` + `}
                </Typography>
                <TextField
                  className="number-input"
                  value={answers.step2Third}
                  onChange={(e) => handleInputChange(e.target.value, 'step2Third')}
                  onKeyDown={handleKeyDown}
                  variant="standard"
                  style={{ width: inputWidth }}
                />
              </>
            )}
          </div>

          {/* Line 3: Final result */}
          <div style={{ display: 'flex', alignItems: 'center', gap: gapSize, whiteSpace: 'nowrap' }}>
            <Typography variant="h5" component="span" style={expressionStyle}>
              {`${number} ${operation} ${secondNumber} = `}
            </Typography>
            <TextField
              className="number-input"
              value={answers.step3}
              onChange={(e) => handleInputChange(e.target.value, 'step3')}
              onKeyDown={handleKeyDown}
              variant="standard"
              style={{ width: inputWidth }}
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default EquivalentExpressionPractice;
